import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/joy/Snackbar";
import * as globalSlice from "../../utils/globalSlice";
import { auth } from "../../utils/FireStore";
import { getUserById } from "../../utils/api/user";
import { updateAllFields } from "../../utils/userSlice";
import { useNavigate } from "react-router-dom";

export default function AppWrapper({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const global = useSelector((state) => state.global);

  useEffect(() => {
    // Add a listener for auth state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userData = await getUserById(user.uid);
          console.log("USER HERE: ", userData);
          dispatch(updateAllFields(userData));

          // reroute if on sign in or sign up
          if (
            window.location.pathname === "/sign-in" ||
            window.location.pathname === "/sign-up"
          ) {
            navigate("/dashboard");
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      }
    });

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, [dispatch]);

  const closeSnackbarHandler = () => {
    dispatch(
      globalSlice.updateFields({
        snackbarOpen: false,
      })
    );
  };

  return (
    <>
      {children}
      <Snackbar
        sx={{ zIndex: 9999 }}
        variant={global.snackbarVariant}
        // anchorOrigin={{ vertical, horizontal }}
        open={global.snackbarOpen}
        color={global.snackbarColor}
        autoHideDuration={4000}
        onClose={closeSnackbarHandler}
      >
        {global.snackbarText}
      </Snackbar>
    </>
  );
}
