import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  snackbarText: "",
  snackbarOpen: false,
  snackbarColor: "success",
  snackbarVariant: "solid",
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    updateFields: (state, action) => {
      const allData = action.payload;
      Object.keys(allData).forEach((key) => {
        state[key] = allData[key];
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateFields } = globalSlice.actions;

export default globalSlice.reducer;
