import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { timestampToISO } from "./helperFunctions";

const initialState = {
  companyIds: [],
  createdAt: "",
  displayName: "",
  email: "",
  updatedAt: "",
};

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    updateAllFields: (state, action) => {
      const allData = action.payload;
      Object.keys(allData).forEach((key) => {
        if (key === "updatedAt" || key === "createdAt") {
          state[key] = timestampToISO(allData[key]);
          return;
        }
        state[key] = allData[key];
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateField, updateAllFields } = user.actions;

export default user.reducer;
