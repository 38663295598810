import React, { useState } from "react";
import _ from "lodash";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import IconButton from "@mui/joy/IconButton";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import LinearProgress from "@mui/joy/LinearProgress";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { auth, storage } from "../../utils/FireStore";
import { signOut } from "firebase/auth";
import { getCompanyById, updateCompanyById } from "../../utils/api/company";
import { setCompanyData } from "../../utils/companySlice";
import { getUserCompanyId } from "../../utils/api/user";
import * as globalSlice from "../../utils/globalSlice";
import { useDispatch, useSelector } from "react-redux";

// import DropZone from "./DropZone";
// import FileUpload from "./FileUpload";
// import CountrySelector from "./CountrySelector";
// import EditorToolbar from "./EditorToolbar";

export default function UserPage() {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);
  const companyInfo = {
    companyAddress: company["companyAddress"] || "",
    companyName: company["companyName"] || "",
    companyPhone: company["companyPhone"] || "",
    companyWebsite: company["companyWebsite"] || "",
    companyEmail: company["companyEmail"] || "",
    companyLogo: company["companyLogo"] || "",
  };

  const [newCompanyInfo, setNewCompanyInfo] = useState(companyInfo);
  const [openNewLogoModal, setOpenNewLogoModal] = useState(false);
  const [image, setImage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCompanyInfo({ ...newCompanyInfo, [name]: value });
  };

  const [preview, setPreview] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setPreview(URL.createObjectURL(file));
      handleUpload(file);
    }
    // setOpenNewLogoModal(false);
  };

  const handleUpdateCompanyInfo = async () => {
    const formattedCompanyInfo = { ...newCompanyInfo };
    if (formattedCompanyInfo.companyWebsite) {
      function formatWebsite(url) {
        return /^(https?:\/\/)/.test(url) ? url : `https://${url}`;
      }

      const formattedWebsite = formatWebsite(newCompanyInfo.companyWebsite);
      formattedCompanyInfo.companyWebsite = formattedWebsite;
    }
    updateCompanyById(company.id, formattedCompanyInfo)
      .then((res) => {
        dispatch(setCompanyData(formattedCompanyInfo));
        dispatch(
          globalSlice.updateFields({
            snackbarText: "Company info updated",
            snackbarOpen: true,
            snackbarColor: "success",
          })
        );
      })
      .catch((err) => alert("Error updating company info: ", err));
  };

  const handleUpload = (image) => {
    if (image) {
      const storageRef = ref(storage, `images/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Progress function
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          // Error function
          console.error("Upload failed", error);
        },
        () => {
          // Complete function
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // setUrl(downloadURL);
            setNewCompanyInfo({ ...newCompanyInfo, companyLogo: downloadURL });
            console.log("File available at", downloadURL);
            setOpenNewLogoModal(false);
            setProgress(0);
          });
        }
      );
    }
  };

  const companyInfoIsEqual = () => {
    return _.isEqual(companyInfo, newCompanyInfo);
  };

  const signOutHandler = () => {
    signOut(auth);
  };

  return (
    <>
      <Box sx={{ flex: 1, width: "100%" }}>
        <Box
          sx={{
            position: "sticky",
            top: { sm: -100, md: -110 },
            bgcolor: "background.body",
            zIndex: 9995,
          }}
        ></Box>
        <Stack
          spacing={4}
          sx={{
            display: "flex",
            maxWidth: "800px",
            mx: "auto",
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">Account Info</Typography>
            </Box>
            <Divider />
            <Stack direction="row" spacing={3} sx={{ my: 1 }}>
              <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Stack
                  spacing={1}
                  sx={{
                    display: { sm: "flex-row", md: "flex-row" },
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography level="title-md">Username:</Typography>
                    <Typography level="body-md">{user.displayName}</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography level="title-md">Email:</Typography>
                    <Typography level="body-md">{user.email}</Typography>
                  </Box>
                  {/* <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography level="title-md">Companies:</Typography>
                    {user.companyIds.map((id) => (
                      <Typography level="body-md" key={id}>
                        {id}
                      </Typography>
                    ))}
                  </Box> */}
                </Stack>
              </Stack>
            </Stack>
          </Card>
          <Button onClick={signOutHandler}>Sign Out</Button>
        </Stack>
      </Box>
    </>
  );
}
