import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  features: [],
  featuresFenceType: {},
  gates: [],
  selectedGate: {},
  selectedFence: {},
  isGateModalOpen: false,
  isAddingGate: false,
  notes: "",
  desiredInstallationDate: "",
};

export const customer = createSlice({
  name: "customer",
  initialState,
  reducers: {
    updateField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    setSelectedGate: (state, action) => {
      state.selectedGate = action.payload;
    },
    setSelectedFence: (state, action) => {
      state.selectedFence = action.payload;
    },
    addGate: (state, action) => {
      const gatePayload = action.payload;
      const newGate = {
        geometry: {
          coordinates: gatePayload.coordinates,
        },
        ...gatePayload,
        id: uuidv4(),
        ...state.selectedGate,
        type: "gate",
      };
      state["gates"].push(newGate);
      if (gatePayload.parentFenceId) {
        if (state.featuresFenceType[gatePayload.parentFenceId].gates) {
          state.featuresFenceType[gatePayload.parentFenceId].gates.push(
            newGate.id
          );
        } else {
          state.featuresFenceType[gatePayload.parentFenceId].gates = [
            newGate.id,
          ];
        }
      }
    },
    removeGate: (state, action) => {
      const gateId = action.payload;

      const index = state["gates"].findIndex((obj) => obj.id === gateId);

      if (state["gates"][index] && state["gates"][index].parentFenceId) {
        const parentFenceId = state["gates"][index].parentFenceId;
        if (state.featuresFenceType[parentFenceId].gates) {
          const gateIndex = state.featuresFenceType[
            parentFenceId
          ].gates.findIndex((id) => id === gateId);
          state.featuresFenceType[parentFenceId].gates.splice(gateIndex, 1);
        }
      }

      if (index !== -1) {
        // Remove the object from the array
        state["gates"].splice(index, 1);
      } else {
        console.log(`No gate with id: `, gateId);
      }
    },
    setFeatureFenceType: (state, action) => {
      const { fenceType, id } = action.payload;
      console.log("fenceType: ", fenceType);
      console.log("id: ", id);

      // Object.keys(fenceType).forEach((key) => {
      //   state.featuresFenceType[id][key] = fenceType[key];
      // });

      state.featuresFenceType[id] = {
        ...state.featuresFenceType[id],
        ...fenceType,
      };
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    removeFeatureFenceType: (state, action) => {
      const { id } = action.payload;
      const gates = state.featuresFenceType[id].gates;

      //remove gates from state.gates before deleting the fence
      gates &&
        gates.forEach((gateId) => {
          const index = state["gates"].findIndex((obj) => obj.id === gateId);
          if (index !== -1) {
            // Remove the object from the array
            state["gates"].splice(index, 1);
          } else {
            console.log(`No gate with id: `, gateId);
          }
        });

      delete state.featuresFenceType[id];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFeatureFenceType,
  updateField,
  incrementByAmount,
  addGate,
  removeGate,
  setSelectedGate,
  removeFeatureFenceType,
  setSelectedFence,
} = customer.actions;

export default customer.reducer;
