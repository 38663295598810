import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  createGlobalOption,
  updateGlobalOption,
  deleteGlobalOption,
} from "../../utils/api/options";
import AspectRatio from "@mui/joy/AspectRatio";
import Typography from "@mui/joy/Typography";
import GateChip from "./GateChip";
import Box from "@mui/joy/Box";

export default function GateCard({
  type,
  children,
  data,
  nestedList,
  globalMap,
  collectionName,
  getAllGateData,
  nestedListName,
  parentChecklist,
  currentListName,
}) {
  console.log("type: ", type);
  if (type !== "fence" && type !== "gate")
    return console.error("Invalid type: please use 'fence' or 'gate'");
  return (
    <Card
      sx={{ width: 330 }}
      variant="solid"
      color="primary"
      style={{ border: "solid 1px grey", margin: 10 }}
    >
      {data && (
        <>
          <IconButton
            aria-label="bookmark Bahamas Islands"
            variant="plain"
            color="neutral"
            size="sm"
            sx={{
              position: "absolute",
              top: "0.875rem",
              right: "0.5rem",
            }}
          >
            <DeleteForeverIcon
              onClick={() => {
                if (parentChecklist && parentChecklist.length > 0) {
                  let hasMaterial = false;
                  parentChecklist.forEach((parentItem) => {
                    parentItem[currentListName].forEach((nestedItem) => {
                      if (nestedItem === data.id) {
                        hasMaterial = true;
                        return;
                      }
                    });
                  });

                  if (hasMaterial) {
                    alert(
                      `This ${data.option} is in use and cannot be deleted`
                    );
                  } else {
                    deleteGlobalOption(data.id, collectionName).then((res) => {
                      getAllGateData();
                    });
                  }
                } else {
                  deleteGlobalOption(data.id, collectionName).then((res) => {
                    getAllGateData();
                  });
                }
              }}
            />
          </IconButton>
          <Typography level="h3">{data.name}</Typography>
          <AspectRatio minHeight="120px" maxHeight="200px">
            <img src={data.imageUrl} loading="lazy" alt="" />
          </AspectRatio>
          <Typography sx={{ color: "white" }} level="body-sm">
            <strong>Id:</strong> {data.id}
          </Typography>
          <Typography sx={{ color: "white" }} level="body-sm">
            <strong>Type:</strong> {data.type}
          </Typography>
          <Typography sx={{ color: "white" }} level="body-sm">
            <strong>Option:</strong> {data.option}
          </Typography>
          <Box sx={{ gap: 6 }}>
            <Typography sx={{ color: "white" }} level="body-sm">
              <strong>Children:</strong>
            </Typography>
            {nestedList.map((nestedItemId) => {
              return (
                <GateChip
                  id={nestedItemId}
                  deleteFunction={() => {
                    const newGlobalArray = [...data[nestedListName]];
                    const itemIndex = newGlobalArray.indexOf(nestedItemId);
                    newGlobalArray.splice(itemIndex, 1);

                    updateGlobalOption(
                      {
                        id: data.id,
                        [nestedListName]: newGlobalArray,
                      },
                      collectionName
                    )
                      .then((res) => {
                        if (
                          type === "fence"
                            ? collectionName === "attributes"
                            : collectionName === "gateAttributes"
                        ) {
                          deleteGlobalOption(
                            nestedItemId,
                            type === "fence" ? "items" : "gateItems"
                          )
                            .then((res) => {
                              getAllGateData();
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        } else {
                          getAllGateData();
                        }
                      })
                      .catch((err) => {
                        console.log("Error updating global option", err);
                      });
                  }}
                >
                  {globalMap[nestedItemId] && globalMap[nestedItemId].name}
                </GateChip>
              );
            })}
          </Box>
        </>
      )}
      {children}
    </Card>
  );
}
