import React, { useState, useEffect } from "react";
import MapPage from "../MapPage";
import { getQuoteById } from "../../utils/api/quote";
import { getCompanyById } from "../../utils/api/company";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateField } from "../../utils/customerSlice";
import { setCompanyData } from "../../utils/companySlice";
import { getAllCollection } from "../../utils/api/options";
import { timestampToISO } from "../../utils/helperFunctions";
import { setGlobalData } from "../../utils/companySlice";

export default function MapPageContainer() {
  const dispatch = useDispatch();
  const [foundQuote, setFoundQuote] = useState(null);
  const { quoteId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getQuoteById(quoteId)
      .then((quote) => {
        if (!quote) {
          navigate("/404");
        }
        setFoundQuote({ id: quoteId, ...quote });
        dispatch(
          updateField({
            field: "details",
            value: { id: quoteId, ...quote },
          })
        );

        getCompanyById(quote.companyId)
          .then((company) => {
            dispatch(updateField({ field: "company", value: company }));
            dispatch(setCompanyData(company));
          })
          .catch((err) => {
            console.log("Couldn't find company: ", err);
          });

        getAllCollection("styles")
          .then((res) => {
            const newDataList = res.map((option) => {
              return {
                ...option,
                createdAt: timestampToISO(option.createdAt),
              };
            });
            dispatch(
              setGlobalData({
                data: newDataList,
                listName: "globalStylesList",
                mapName: "globalStylesMap",
              })
            );
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        getAllCollection("materials")
          .then((res) => {
            const newDataList = res.map((option) => {
              return {
                ...option,
                createdAt: timestampToISO(option.createdAt),
              };
            });
            dispatch(
              setGlobalData({
                data: newDataList,
                listName: "globalMaterialsList",
                mapName: "globalMaterialsMap",
              })
            );
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        getAllCollection("attributes")
          .then((res) => {
            const newDataList = res.map((option) => {
              return {
                ...option,
                createdAt: timestampToISO(option.createdAt),
              };
            });
            dispatch(
              setGlobalData({
                data: newDataList,
                listName: "globalAttributesList",
                mapName: "globalAttributesMap",
              })
            );
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        getAllCollection("items")
          .then((res) => {
            const newDataList = res.map((option) => {
              return {
                ...option,
                createdAt: timestampToISO(option.createdAt),
              };
            });
            dispatch(
              setGlobalData({
                data: newDataList,
                mapName: "globalItemsMap",
                listName: "globalItemsList",
              })
            );
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => console.log("Couldn't find quote: ", err));
  }, []);

  if (!foundQuote) {
    return null;
  }
  return <MapPage quoteData={foundQuote} />;
}
