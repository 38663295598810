import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db, auth } from "../FireStore";

export async function createUser(userData) {
  // Get the current user
  const user = auth.currentUser;

  if (!user) {
    throw new Error("No authenticated user found.");
  }

  // Use the user's UID as the document ID
  const userRef = doc(db, "users", user.uid);

  // Use setDoc to create or update the document with the userData
  await setDoc(userRef, { ...userData, createdAt: serverTimestamp() });

  // Return a message with the user ID
  return `User with ID ${user.uid} created/updated successfully.`;
}

export async function getUserById(userId) {
  // Use doc to get a reference to the document and getDoc to retrieve it
  const userDoc = await getDoc(doc(db, "users", userId));

  if (userDoc.exists()) {
    return userDoc.data();
  } else {
    throw new Error("User not found");
  }
}

export async function updateUser(userId, updatedData) {
  try {
    // Reference to the user's document in the "users" collection
    const userDocRef = doc(db, "users", userId);

    // Prepare the update object
    const updatePayload = {
      ...updatedData,
      updatedAt: serverTimestamp(),
    };

    // Check if updatedData contains companyIdsToAdd and companyIdsToRemove fields
    if (updatedData.companyIdsToAdd) {
      updatePayload.companyIds = arrayUnion(...updatedData.companyIdsToAdd);
    }
    if (updatedData.companyIdsToRemove) {
      updatePayload.companyIds = arrayRemove(...updatedData.companyIdsToRemove);
    }

    // Remove the temporary fields from updatedData to avoid unnecessary writes
    delete updatePayload.companyIdsToAdd;
    delete updatePayload.companyIdsToRemove;

    // Update the user document with the new data
    await updateDoc(userDocRef, updatePayload);

    return { success: true, message: "User updated successfully" };
  } catch (error) {
    throw new Error("Error updating user: " + error.message);
  }
}

export async function getUserCompanyId() {
  const user = auth.currentUser;
  if (user) {
    const userDocRef = doc(db, "users", user.uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      const companyId = userData.companyIds && userData.companyIds[0];
      return companyId || null;
    } else {
      console.log("No user data found!");
    }
  } else {
    console.log("user not signed in");
  }
}
