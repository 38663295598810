import { useEffect, useState } from "react";
import { auth } from "./utils/FireStore";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getUserCompanyId } from "./utils/api/user";
import { getCompanyById } from "./utils/api/company";

const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userCompanyId, setUserCompanyId] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        getUserCompanyId()
          .then((id) => {
            if (!id) {
              navigate("/create-company");
              return;
            }
            getCompanyById(id)
              .then((company) => {
                setUserCompanyId(id);
                setCompanyData(company);
              })
              .catch((err) => {
                console.log("Error getting and setting company data: ", err);
              });
          })
          .catch((err) => {
            console.log(
              "Error getting user company id, forwarding to /create-company ",
              err
            );
            navigate("/create-company");
          });
      } else {
        navigate("/sign-in");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (!user) {
    return null; // or a loading spinner
  }

  if (!user) {
    return null; // or a loading spinner
  }

  return children;
};

export default ProtectedRoute;
