import * as React from "react";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import { useNavigate } from "react-router-dom";
import settings from "../../utils/settings.json";
import { auth } from "../../utils/FireStore"; // Import the auth instance
import { getUserById } from "../../utils/api/user";
import setupCompanyImage from "../../assets/images/setupCompanyImage.webp";
import { getFunctions, httpsCallable } from "firebase/functions";
import { addStripeCustomer } from "../../utils/firebaseFunctions";

// import {
//   signInWithEmailAndPassword,
//   sendPasswordResetEmail,
// } from "firebase/auth";

function ColorSchemeToggle(props) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <></>
    // <IconButton
    //   aria-label="toggle light/dark mode"
    //   size="sm"
    //   variant="outlined"
    //   disabled={!mounted}
    //   onClick={(event) => {
    //     setMode(mode === "light" ? "dark" : "light");
    //     onClick?.(event);
    //   }}
    //   {...other}
    // >
    //   {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    // </IconButton>
  );
}

export default function CreateCompanyPage() {
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({});
  const user = auth.currentUser;

  React.useEffect(() => {
    getUserById(user.uid)
      .then((data) => {
        setUserData(data);
      })
      .catch((err) => console.log("error getting user data: ", err));
  }, []);

  const runFunctionHandler = async (data) => {
    addStripeCustomer(user.uid, data)
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        console.log("result: ", result);

        navigate(`/dashboard`);
      })
      .catch((e) => {
        console.error("Error creating company: ", e);
      });
  };

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s", // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: "100%", md: "50vw" },
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(255 255 255 / 0.2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
              <IconButton variant="soft" color="primary" size="sm">
                <BadgeRoundedIcon />
              </IconButton>
              <Typography level="title-lg">{settings.companyName}</Typography>
            </Box>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: "hidden",
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  Hey {userData.displayName}, <br></br>Let's get your account
                  setup!
                </Typography>
                <Typography level="body-sm">
                  How do you want customers to see your company?
                </Typography>
              </Stack>
            </Stack>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form
                onSubmit={async (event) => {
                  event.preventDefault();
                  const formElements = event.currentTarget.elements;
                  const data = {
                    companyName: formElements.companyName.value,
                    companyEmail: formElements.companyEmail.value,
                    companyPhone: formElements.companyPhone.value,
                    companyAddress: formElements.companyAddress.value,
                  };

                  runFunctionHandler(data);
                }}
              >
                <FormControl required>
                  <FormLabel>Company Name</FormLabel>
                  <Input type="text" name="companyName" />
                </FormControl>
                <FormControl required>
                  <FormLabel>Company Contact Email</FormLabel>
                  <Input type="text" name="companyEmail" />
                </FormControl>
                <FormControl>
                  <FormLabel>Company Phone</FormLabel>
                  <Input type="text" name="companyPhone" />
                </FormControl>
                <FormControl>
                  <FormLabel>Company Address</FormLabel>
                  <Input type="text" name="companyAddress" />
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  <Button type="submit" fullWidth>
                    Complete
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © {settings.companyName} {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: "50vw" },
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${setupCompanyImage})`,
          [theme.getColorSchemeSelector("dark")]: {
            backgroundImage: `url(${setupCompanyImage})`,
          },
        })}
      />
    </CssVarsProvider>
  );
}
