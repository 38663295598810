// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import firebase from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "picket-10703.firebaseapp.com",
  projectId: "picket-10703",
  storageBucket: "picket-10703.appspot.com",
  messagingSenderId: "1033915666789",
  appId: "1:1033915666789:web:0b93ad20ba49f01909118c",
  measurementId: "G-BZXXRSSXXR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const db = getFirestore(app); // Get Firestore instance

export { db, storage, auth, functions };
