import React, { useEffect, useState } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import IconButton from "@mui/joy/IconButton";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import TabPanel from "@mui/joy/TabPanel";
import QuoteDashboard from "../QuoteDashboard";
import CompanyPage from "../CompanyPage";
import UserPage from "../UserPage";
import CatalogPage from "../CatalogPage";
import CatalogPageNew from "../CatalogPageNew";
import GateCatalogPage from "../GateCatalogPage";
import GateCatalogPageNew from "../GateCatalogPageNew";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { auth } from "../../utils/FireStore";
import { signOut } from "firebase/auth";
import { getCompanyById } from "../../utils/api/company";
import { getUserCompanyId } from "../../utils/api/user";
import { getQuotesByCompanyId } from "../../utils/api/quote";
import {
  createGlobalOption,
  createGlobalType,
  getAllOptions,
  getAllTypes,
  getAllCollection,
} from "../../utils/api/options";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompanyData,
  setGlobalOptions,
  setGlobalTypes,
  setGlobalData,
} from "../../utils/companySlice";
import { optionsObject, fenceTypeslist } from "../../utils/globalFenceData";
import { timestampToISO } from "../../utils/helperFunctions";

// import DropZone from "./DropZone";
// import FileUpload from "./FileUpload";
// import CountrySelector from "./CountrySelector";
// import EditorToolbar from "./EditorToolbar";

import {
  firebaseStyles,
  firebaseMaterials,
  firebaseAttributes,
} from "../../utils/newGlobalData";

import firestoreData from "../../mockData/firestoreData.json";
import AddGlobalDataPage from "../AddGlobalDataPage";
import AddGlobalDataPageNew from "../AddGlobalDataPage";
import AddGlobalGateDataPage from "../AddGlobalGateDataPage";
import FenceLinkLogo from "../../assets/icons/link-icon-512.png";
import FenceLinkNameLogo from "../../assets/icons/name-logo/Component 1.svg";

export default function DashboardPage() {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const user = useSelector((state) => state.user);
  const [direction, setDirection] = useState("column");
  const [placement, setPlacement] = useState("bottom");
  const [companyId, setCompanyId] = useState(null);

  useEffect(() => {
    getUserCompanyId()
      .then((companyId) => {
        setCompanyId(companyId);
        if (!companyId) return;
        getCompanyById(companyId).then((res) => {
          const { createdAt, ...rest } = res;
          const companyData = {
            ...rest,
            createdAt: new Date(createdAt.seconds * 1000).toISOString(),
            id: companyId,
          };

          dispatch(setCompanyData(companyData));
        });
        getQuotesByCompanyId(companyId)
          .then((resQuotes) => {
            const newQuoteData = resQuotes.map((quote) => {
              return {
                ...quote,
                createdAt: new Date(
                  quote.createdAt.seconds * 1000
                ).toISOString(),
              };
            });
            console.log("newQuoteData: :", newQuoteData);
            dispatch(setCompanyData({ quotes: newQuoteData }));
          })
          .catch((err) => console.log("failed to get quotes: ", err));

        getAllCollection("styles")
          .then((res) => {
            const newDataList = res.map((option) => {
              return {
                ...option,
                createdAt: timestampToISO(option.createdAt),
                updatedAt: option.updatedAt
                  ? timestampToISO(option.updatedAt)
                  : null,
              };
            });
            dispatch(
              setGlobalData({
                data: newDataList,
                listName: "globalStylesList",
                mapName: "globalStylesMap",
              })
            );
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        getAllCollection("materials")
          .then((res) => {
            const newDataList = res.map((option) => {
              return {
                ...option,
                createdAt: timestampToISO(option.createdAt),
                updatedAt: option.updatedAt
                  ? timestampToISO(option.updatedAt)
                  : null,
              };
            });
            dispatch(
              setGlobalData({
                data: newDataList,
                listName: "globalMaterialsList",
                mapName: "globalMaterialsMap",
              })
            );
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        getAllCollection("attributes")
          .then((res) => {
            const newDataList = res.map((option) => {
              return {
                ...option,
                createdAt: timestampToISO(option.createdAt),
                updatedAt: option.updatedAt
                  ? timestampToISO(option.updatedAt)
                  : null,
              };
            });
            dispatch(
              setGlobalData({
                data: newDataList,
                listName: "globalAttributesList",
                mapName: "globalAttributesMap",
              })
            );
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });

        getAllCollection("items")
          .then((res) => {
            const newDataList = res.map((option) => {
              return {
                ...option,
                createdAt: timestampToISO(option.createdAt),
                updatedAt: option.updatedAt
                  ? timestampToISO(option.updatedAt)
                  : null,
              };
            });
            dispatch(
              setGlobalData({
                data: newDataList,
                mapName: "globalItemsMap",
                listName: "globalItemsList",
              })
            );
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getAllGateData();
  }, []);

  const getAllGateData = () => {
    getAllCollection("gateStyles")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            listName: "globalGateStylesList",
            mapName: "globalGateStylesMap",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCollection("gateMaterials")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            listName: "globalGateMaterialsList",
            mapName: "globalGateMaterialsMap",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCollection("gateAttributes")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            listName: "globalGateAttributesList",
            mapName: "globalGateAttributesMap",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getAllCollection("gateItems")
      .then((res) => {
        const newDataList = res.map((option) => {
          return {
            ...option,
            createdAt: timestampToISO(option.createdAt),
            updatedAt: option.updatedAt
              ? timestampToISO(option.updatedAt)
              : null,
          };
        });
        dispatch(
          setGlobalData({
            data: newDataList,
            mapName: "globalGateItemsMap",
            listName: "globalGateItemsList",
          })
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const signOutHandler = () => {
    signOut(auth);
  };

  return (
    <Box sx={{ flex: 1, width: "100%" }}>
      <Box sx={{ px: { xs: 2, md: 6 } }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}
        >
          <Link href="/dashboard" color="neutral" aria-label="Home">
            {/* <FenceLinkNameLogo/> */}
            <img width={120} src={FenceLinkNameLogo} loading="lazy" alt="" />
          </Link>
          <Typography color="neutral" fontSize={12} fontWeight={500}>
            {user.displayName}
          </Typography>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            {company.companyName}
          </Typography>
        </Breadcrumbs>
        <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
          {company.companyName}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "sticky",
          top: { sm: -100, md: -110 },
          bgcolor: "background.body",
        }}
      >
        <Tabs
          defaultValue={0}
          sx={{
            bgcolor: "transparent",
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: { xs: 0, md: 4 },
              justifyContent: "left",
              [`&& .${tabClasses.root}`]: {
                fontWeight: "600",
                flex: "initial",
                color: "text.tertiary",
                [`&.${tabClasses.selected}`]: {
                  bgcolor: "transparent",
                  color: "text.primary",
                  "&::after": {
                    height: "2px",
                    bgcolor: "primary.500",
                  },
                },
              },
            }}
          >
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={0}>
              Quote Dashboard
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={6}>
              Fence Catalog
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={7}>
              Gate Catalog
            </Tab>

            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={2}>
              Company Settings
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={5}>
              Account
            </Tab>

            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={8}>
              Add Global Fence Data
            </Tab>
            <Tab sx={{ borderRadius: "6px 6px 0 0" }} indicatorInset value={4}>
              Add Global Gate Data
            </Tab>
          </TabList>
          <TabPanel value={0}>
            <QuoteDashboard />
          </TabPanel>
          <TabPanel value={6}>
            <CatalogPageNew />
          </TabPanel>

          <TabPanel value={2}>
            <CompanyPage />
          </TabPanel>
          <TabPanel value={5}>
            <UserPage />
          </TabPanel>

          <TabPanel value={8}>
            <AddGlobalDataPageNew />
          </TabPanel>
          <TabPanel value={4}>
            <AddGlobalGateDataPage />
          </TabPanel>

          <TabPanel value={7}>
            <GateCatalogPageNew />
          </TabPanel>
        </Tabs>
      </Box>
    </Box>
  );
}
