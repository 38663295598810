import { v4 as uuidv4 } from "uuid";

export const firebaseAttributes = [
  {
    name: "height",
    option: "attribute",
    optionsList: [
      { id: uuidv4(), name: "1 feet" },
      { id: uuidv4(), name: "2 feet" },
      { id: uuidv4(), name: "3 feet" },
      { id: uuidv4(), name: "4 feet" },
      { id: uuidv4(), name: "5 feet" },
      { id: uuidv4(), name: "6 feet" },
      { id: uuidv4(), name: "7 feet" },
      { id: uuidv4(), name: "8 feet" },
      { id: uuidv4(), name: "9 feet" },
      { id: uuidv4(), name: "10 feet" },
      { id: uuidv4(), name: "11 feet" },
      { id: uuidv4(), name: "12 feet" },
    ],
  },
  {
    name: "stain",
    option: "attribute",
    optionsList: [
      { id: uuidv4(), name: "none" },
      { id: uuidv4(), name: "oak" },
      { id: uuidv4(), name: "cherry" },
      { id: uuidv4(), name: "walnut" },
      { id: uuidv4(), name: "mahogany" },
    ],
  },
];

export const firebaseMaterials = [
  {
    name: "cedar",
    option: "material",
    optionsList: [],
  },
  {
    name: "pine",
    option: "material",
    optionsList: [],
  },
  {
    name: "oak",
    option: "material",
    optionsList: [],
  },
];

export const firebaseStyles = [
  {
    name: "Picket",
    type: "wood",
    option: "style",
    optionsList: [],
  },
  {
    name: "Good Neighbor",
    type: "wood",
    option: "style",
    optionsList: [],
  },
  {
    name: "Shadow Box",
    type: "wood",
    option: "style",
    optionsList: [],
  },
];

export const newGlobalTypes = [
  {
    id: uuidv4(),
    type: "wood",
    name: "picket",
    materials: [
      {
        id: uuidv4(),
        name: "cedar",
        basePrice: 10,
        options: [
          {
            name: "height",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "4 feet", price: 2 },
              { id: uuidv4(), name: "6 feet", price: 4 },
              { id: uuidv4(), name: "8 feet", price: 6 },
            ],
          },
          {
            name: "stain",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "none", price: 0 },
              { id: uuidv4(), name: "oak", price: 1 },
              { id: uuidv4(), name: "cherry", price: 2 },
              { id: uuidv4(), name: "walnut", price: 3 },
              { id: uuidv4(), name: "mahogany", price: 4 },
            ],
          },
        ],
      },
      {
        id: uuidv4(),
        name: "pine",
        basePrice: 5,
        options: [
          {
            name: "height",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "4 feet", price: 2 },
              { id: uuidv4(), name: "6 feet", price: 7 },
              { id: uuidv4(), name: "8 feet", price: 5 },
            ],
          },
          {
            name: "stain",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "none", price: 0 },
              { id: uuidv4(), name: "oak", price: 1 },
              { id: uuidv4(), name: "cherry", price: 1 },
              { id: uuidv4(), name: "walnut", price: 1 },
              { id: uuidv4(), name: "mahogany", price: 2 },
            ],
          },
        ],
      },
      {
        id: uuidv4(),
        name: "oak",
        basePrice: 15,
        options: [
          {
            name: "height",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "4 feet", price: 4 },
              { id: uuidv4(), name: "6 feet", price: 6 },
              { id: uuidv4(), name: "8 feet", price: 8 },
            ],
          },
          {
            name: "stain",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "none", price: 0 },
              { id: uuidv4(), name: "oak", price: 1 },
              { id: uuidv4(), name: "cherry", price: 2 },
              { id: uuidv4(), name: "walnut", price: 3 },
              { id: uuidv4(), name: "mahogany", price: 4 },
            ],
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    type: "wood",
    name: "good neighbor",
    materials: [
      {
        id: uuidv4(),
        name: "cedar",
        basePrice: 10,
        options: [
          {
            name: "height",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "4 feet", price: 2 },
              { id: uuidv4(), name: "6 feet", price: 4 },
              { id: uuidv4(), name: "8 feet", price: 6 },
            ],
          },
          {
            name: "stain",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "none", price: 0 },
              { id: uuidv4(), name: "oak", price: 1 },
              { id: uuidv4(), name: "cherry", price: 2 },
              { id: uuidv4(), name: "walnut", price: 3 },
              { id: uuidv4(), name: "mahogany", price: 4 },
            ],
          },
        ],
      },
      {
        id: uuidv4(),
        type: "wood",
        name: "pine",
        basePrice: 5,
        options: [
          {
            name: "height",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "4 feet", price: 2 },
              { id: uuidv4(), name: "6 feet", price: 7 },
              { id: uuidv4(), name: "8 feet", price: 5 },
            ],
          },
          {
            name: "stain",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "none", price: 0 },
              { id: uuidv4(), name: "oak", price: 1 },
              { id: uuidv4(), name: "cherry", price: 1 },
              { id: uuidv4(), name: "walnut", price: 1 },
              { id: uuidv4(), name: "mahogany", price: 2 },
            ],
          },
        ],
      },
      {
        id: uuidv4(),
        name: "oak",
        basePrice: 15,
        options: [
          {
            name: "height",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "4 feet", price: 4 },
              { id: uuidv4(), name: "6 feet", price: 6 },
              { id: uuidv4(), name: "8 feet", price: 8 },
            ],
          },
          {
            name: "stain",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "none", price: 0 },
              { id: uuidv4(), name: "oak", price: 1 },
              { id: uuidv4(), name: "cherry", price: 2 },
              { id: uuidv4(), name: "walnut", price: 3 },
              { id: uuidv4(), name: "mahogany", price: 4 },
            ],
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    type: "wood",
    name: "shadow box",
    materials: [
      {
        id: uuidv4(),
        name: "cedar",
        basePrice: 14,
        options: [
          {
            name: "height",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "4 feet", price: 2 },
              { id: uuidv4(), name: "6 feet", price: 4 },
              { id: uuidv4(), name: "8 feet", price: 6 },
            ],
          },
          {
            name: "stain",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "none", price: 0 },
              { id: uuidv4(), name: "oak", price: 1 },
              { id: uuidv4(), name: "cherry", price: 2 },
              { id: uuidv4(), name: "walnut", price: 3 },
              { id: uuidv4(), name: "mahogany", price: 4 },
            ],
          },
        ],
      },
      {
        id: uuidv4(),
        type: "wood",
        name: "pine",
        basePrice: 5,
        options: [
          {
            name: "height",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "4 feet", price: 2 },
              { id: uuidv4(), name: "6 feet", price: 7 },
              { id: uuidv4(), name: "8 feet", price: 5 },
            ],
          },
          {
            name: "stain",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "none", price: 0 },
              { id: uuidv4(), name: "oak", price: 1 },
              { id: uuidv4(), name: "cherry", price: 1 },
              { id: uuidv4(), name: "walnut", price: 1 },
              { id: uuidv4(), name: "mahogany", price: 2 },
            ],
          },
        ],
      },
      {
        id: uuidv4(),
        name: "oak",
        basePrice: 15,
        options: [
          {
            name: "height",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "4 feet", price: 4 },
              { id: uuidv4(), name: "6 feet", price: 6 },
              { id: uuidv4(), name: "8 feet", price: 8 },
            ],
          },
          {
            name: "stain",
            id: uuidv4(),
            list: [
              { id: uuidv4(), name: "none", price: 0 },
              { id: uuidv4(), name: "oak", price: 1 },
              { id: uuidv4(), name: "cherry", price: 2 },
              { id: uuidv4(), name: "walnut", price: 3 },
              { id: uuidv4(), name: "mahogany", price: 4 },
            ],
          },
        ],
      },
    ],
  },
];

export const newGlobalTypesMap = newGlobalTypes.reduce((acc, type) => {
  acc[type.id] = type;
  return acc;
}, {});

// export const newGlobalData = {
//   globalStyles: [
//     newGlobalTypes.map((type) => type.id),
//   ],
// }
