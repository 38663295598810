import React, { useEffect, useState, useRef } from "react";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import "./index.css";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import AspectRatio from "@mui/joy/AspectRatio";

import axios from "axios";
import { useDispatch } from "react-redux";
import { updateField } from "../../utils/customerSlice";
import { useParams } from "react-router-dom";
import { getCompanyById } from "../../utils/api/company";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import settings from "../../utils/settings.json";
import Stack from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { createQuote } from "../../utils/api/quote";
import { sendEmail } from "../../utils/firebaseFunctions";

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_TOKEN;

function ColorSchemeToggle(props) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <></>
    // <IconButton
    //   aria-label="toggle light/dark mode"
    //   size="sm"
    //   variant="outlined"
    //   disabled={!mounted}
    //   onClick={(event) => {
    //     setMode(mode === "light" ? "dark" : "light");
    //     onClick?.(event);
    //   }}
    //   {...other}
    // >
    //   {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    // </IconButton>
  );
}

const CustomerInfoPage = () => {
  const { companyId } = useParams();
  const submitButtonRef = useRef(null);
  const [company, setCompany] = useState(null);
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch();
  const [contactInfo, setContactInfo] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    if (!companyId) {
      return;
    }
    getCompanyById(companyId)
      .then((companyData) => {
        setCompany(companyData);
      })
      .catch((err) => console.log("err: ", err));
  }, [companyId]);

  const handleSuggestions = async (value) => {
    if (value.length > 2) {
      try {
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            value
          )}.json`,
          {
            params: {
              access_token: MAPBOX_API_KEY,
              autocomplete: true,
              limit: 5,
            },
          }
        );
        setSuggestions(response.data.features);
      } catch (error) {
        console.error("Error fetching address suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (place) => {
    const newContactInfo = { ...contactInfo };
    newContactInfo.address = place.place_name;
    setContactInfo(newContactInfo);

    setSuggestions([]);
    submitButtonRef.current.focus();
  };

  const handleInputChange = (e) => {
    if (e.target.name === "address") {
      handleSuggestions(e.target.value);
    }
    const newContactInfo = { ...contactInfo };
    newContactInfo[e.target.name] = e.target.value;
    setContactInfo(newContactInfo);
  };

  // const handleSubmit = () => {
  //   dispatch(updateField({ field: "address", value: address }));
  //   dispatch(updateField({ field: "name", value: name }));
  //   dispatch(updateField({ field: "email", value: email }));

  //   navigate(`/fence-map/${address}`);
  // };

  if (!company) {
    return null;
  }

  console.log("company: ", company);
  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s", // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: "100%", md: "50vw" },
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(255 255 255 / 0.2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
              <Typography level="body-sm">{company.companyName}</Typography>
            </Box>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: "hidden",
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2, alignItems: "center" }}>
              {company.companyLogo && (
                <Stack direction="column" spacing={1} sx={{ maxWidth: 200 }}>
                  <AspectRatio
                    ratio="1"
                    // maxHeight={200}
                    sx={{ flex: 1, minWidth: 120, borderRadius: "100%" }}
                  >
                    <img src={company.companyLogo} loading="lazy" alt="" />
                  </AspectRatio>
                </Stack>
              )}
              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  {company.companyName}
                </Typography>
                <Typography level="body-sm">
                  Fill out your info to use our free fence quote tool!
                </Typography>
              </Stack>
            </Stack>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form
                onSubmit={async (event) => {
                  event.preventDefault();
                  const formElements = event.currentTarget.elements;
                  const data = {
                    name: formElements.name.value,
                    email: formElements.email.value,
                    phone: formElements.phone.value,
                    address: formElements.address.value,
                    companyId: companyId,
                  };

                  try {
                    createQuote(data)
                      .then((quoteId) => {
                        sendEmail({
                          toEmail: company.companyEmail,
                          subject: `Attention: New Lead`,
                          text: `New request from ${data.name}`,
                          emailContent: `
                                                  <!DOCTYPE html>
                        <html lang="en">
                        <head>
                            <meta charset="UTF-8">
                            <meta name="viewport" content="width=device-width, initial-scale=1.0">
                            <title>New Lead</title>
                            <style>
                                body {
                                    font-family: Arial, sans-serif;
                                    background-color: #f7f7f7;
                                    color: #333;
                                    margin: 0;
                                    padding: 0;
                                }
                                .container {
                                    max-width: 600px;
                                    margin: 0 auto;
                                    background-color: #ffffff;
                                    border-radius: 8px;
                                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                                    overflow: hidden;
                                }
                                .header {
                                    background-color: #007B8F;
                                    padding: 20px;
                                    text-align: center;
                                }
                                .header img {
                                    max-width: 150px;
                                    border-radius: 50%;
                                }
                                .content {
                                    padding: 20px;
                                    text-align: center;
                                }
                                .content h2 {
                                    color: #007B8F;
                                }
                                .content p {
                                    font-size: 16px;
                                    line-height: 1.5;
                                }
                                .button {
                                    display: inline-block;
                                    background-color: #007B8F;
                                    color: #ffffff !important; /* Ensure white text */
                                    text-decoration: none;
                                    padding: 12px 24px;
                                    border-radius: 5px;
                                    margin-top: 20px;
                                    font-weight: bold;
                                }
                                .button:hover {
                                    background-color: #005F6B;
                                }
                                .footer {
                                    background-color: #f1f1f1;
                                    padding: 20px;
                                    text-align: center;
                                    font-size: 14px;
                                    color: #666;
                                }
                                .footer a {
                                    color: #007B8F;
                                    text-decoration: none;
                                }
                            </style>
                        </head>
                        <body>
                            <div class="container">
                                <!-- Header Section -->
                                <div class="header">
                                    <img src="
                                    ${company.companyLogo}
                                    "
                                    alt="${company.companyName} Logo"
                                    >
                                    <h1>${company.companyName}</h1>
                                </div>

                                <!-- Content Section -->
                                <div class="content">
                                    <h2>New Lead Alert</h2>
                                    <p>${
                                      data.name
                                    } filled out the form to use FenceLink</p>
                                    <p>Here is the link to your dashboard page:</p>
                                    <a href="https://picket-10703.web.app/dashboard" class="button" style="color: #ffffff !important;">https://picket-10703.web.app/dashboard</a>
                                </div>

                                    <div>
                                    <h2>Contact Information</h2>
                                    <p><strong>Name:</strong> ${data.name}</p>
                                    <p><strong>Email:</strong> <a href="mailto:${
                                      data.email
                                    }">${data.email}</a></p>
                                    <p><strong>Phone:</strong> <a href="tel:${
                                      data.phone
                                    }">${data.phone}</a></p>
                                    <p><strong>Address:</strong> ${
                                      data.address
                                    }</p>
                                    
                                    </div>

                                <!-- Footer Section -->
                                <div class="footer">
                                    <p><strong>Contact Us:</strong></p>
                                    <p>
                                    ${
                                      company.companyAddress &&
                                      `<strong>Address:</strong>${company.companyAddress}<br>`
                                    }

                                                  ${
                                                    company.companyPhone &&
                                                    `<strong>Phone:</strong> <a href="tel:${company.companyPhone}">${company.companyPhone}</a><br>`
                                                  }

                                                  ${
                                                    company.companyEmail &&
                                                    ` <strong>Email:</strong> <a href="mailto:${company.companyEmail}">${company.companyEmail}</a><br>`
                                                  }
                                        ${
                                          company.companyWebsite &&
                                          `<strong>Website:</strong> <a href="${company.companyWebsite}">${company.companyWebsite}</a>`
                                        }
                                    </p>
                                </div>
                            </div>
                        </body>
                        </html>
                                                  `,
                          fromName: "FenceLink",
                        });
                        navigate(`/fence-map/${quoteId}/${data.address}`);
                      })
                      .catch((e) => {
                        console.log(JSON.stringify(data, null, 2));
                        console.error("Error creating quote: ", e);
                      });
                  } catch (error) {}
                }}
              >
                <FormControl required>
                  <FormLabel>Name</FormLabel>
                  <Input onChange={handleInputChange} type="text" name="name" />
                </FormControl>
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input
                    onChange={handleInputChange}
                    type="text"
                    name="email"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    onChange={handleInputChange}
                    type="text"
                    name="phone"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Property Address</FormLabel>
                  <Input
                    onChange={handleInputChange}
                    type="text"
                    name="address"
                    value={contactInfo.address}
                  />
                </FormControl>
                {suggestions.length > 0 && (
                  <List>
                    {suggestions.map((suggestion) => (
                      <ListItem key={suggestion.id}>
                        <ListItemButton
                          onClick={() => handleSelect(suggestion)}
                        >
                          {suggestion.place_name}
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                )}
                <Stack gap={4} sx={{ mt: 2 }}>
                  <Button
                    ref={submitButtonRef}
                    disabled={
                      !contactInfo.name ||
                      !contactInfo.email ||
                      !contactInfo.address ||
                      !contactInfo.phone
                    }
                    type="submit"
                    fullWidth
                  >
                    {!contactInfo.name ||
                    !contactInfo.email ||
                    !contactInfo.address ||
                    !contactInfo.phone
                      ? "Please enter info"
                      : `Let's get started!`}
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © {settings.companyName} {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: "50vw" },
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(https://images.unsplash.com/photo-1533803786316-b85d29b59a7f?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundImage:
              "url(https://images.unsplash.com/photo-1533803786316-b85d29b59a7f?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
          },
        })}
      />
    </CssVarsProvider>
  );

  // return (
  //   <div className="container">
  //     <div className="overlay">
  //       {/* <Container maxWidth="sm"> */}
  //       <Paper className="form-container" elevation={3}>
  //         <Box
  //           onSubmit={handleSubmit}
  //           component="form"
  //           sx={{
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "center",
  //             mt: 4,
  //           }}
  //         >
  //           <Typography variant="h5" component="h1" gutterBottom>
  //             {company.companyName}
  //           </Typography>
  //           <Typography variant="h5" component="h1" gutterBottom>
  //             Fill out your info then dive into our fence-building tool!
  //           </Typography>
  //           <TextField
  //             label="Name"
  //             variant="outlined"
  //             fullWidth
  //             margin="normal"
  //             required
  //             onChange={(e) => setName(e.target.value)}
  //           />
  //           <TextField
  //             label="Email"
  //             variant="outlined"
  //             fullWidth
  //             margin="normal"
  //             type="email"
  //             required
  //             onChange={(e) => setEmail(e.target.value)}
  //           />
  //           <TextField
  //             id="address-input"
  //             label="Address"
  //             variant="outlined"
  //             fullWidth
  //             value={address}
  //             onChange={handleChange}
  //           />

  //           <Button
  //             onClick={handleSubmit}
  //             variant="contained"
  //             color="primary"
  //             size="large"
  //             sx={{ mt: 3 }}
  //           >
  //             Get Started!
  //           </Button>
  //         </Box>
  //       </Paper>
  //       {/* </Container> */}
  //     </div>
  //   </div>
  // );
};

export default CustomerInfoPage;
