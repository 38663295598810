import React, { useRef, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-sdk/services/geocoding";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import RotateMode from "mapbox-gl-draw-rotate-mode";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import "./index.css";
import ButtonBox from "../../components/ButtonBox";
import { useParams } from "react-router-dom";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import CardMedia from "@mui/material/CardMedia";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import DimensionList from "../../components/DimensionList";
import ChooseFenceDialog from "../../components/ChooseFenceDialog";
import picketsImage from "../../assets/images/wooden-pickets.png";
import metalFenceImage from "../../assets/images/metal-fence.png";
import chainlinkFenceImage from "../../assets/images/chainlink-fence.png";
import { useSelector, useDispatch } from "react-redux";
import {
  addGate,
  removeGate,
  updateField,
  setFeatureFenceType,
} from "../../utils/customerSlice";
import Chip from "@mui/material/Chip";

import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Fab from "@mui/material/Fab";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Tooltip from "@mui/joy/Tooltip";
import { ReactComponent as GateIcon } from "../../assets/images/GateIcon.svg";
import * as MapboxDrawWaypoint from "mapbox-gl-draw-waypoint";
import Alert from "@mui/material/Alert";
import {
  formatFeatureToMapbox,
  isWithinMapBounds,
} from "../../utils/helperFunctions";
import TutorialVideoDialog from "../../components/TutorialVideoDialog";
import ChooseGateModal from "../../components/ChooseGateModal";
import { updateQuoteById } from "../../utils/api/quote";
import { roundNumber, getFeatureLength } from "../../utils/helperFunctions";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
const geocoder = MapboxGeocoder({ accessToken: mapboxgl.accessToken });

const azureMapsKey = process.env.REACT_APP_AZURE_MAP_TOKEN;

const settings = {
  lengthUnit: "feet",
  lengthUnitAbr: "ft",
};

let modes = MapboxDraw.modes;
modes = MapboxDrawWaypoint.enable(modes);

const draw = new MapboxDraw({
  displayControlsDefault: false,
  // Select which mapbox-gl-draw control buttons to add to the map.
  // Set mapbox-gl-draw to draw by default.
  keybindings: true,
  defaultMode: "simple_select",
  modes: modes,
});

const handleKeyDown = (event) => {
  if (event.key === "Delete" || event.key === "Backspace") {
    draw.trash();
  }
};

const fenceTypeList = [
  {
    id: 4123,
    name: "wood",
    imageUrl: picketsImage,
  },
  {
    id: 5123,
    name: "metal",
    imageUrl: metalFenceImage,
  },
  {
    id: 6234,
    name: "chainlink",
    imageUrl: chainlinkFenceImage,
  },
];

const MapPage = ({ quoteData }) => {
  const { address } = useParams();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null); // Ref to keep track of the marker
  const [currentFeatures, setCurrentFeatures] = useState([]);
  const [lng, setLng] = useState(-120.15696612059628);
  const [lat, setLat] = useState(47.88570913368241);
  const [zoom, setZoom] = useState(20);
  const [newAddress, setNewAddress] = useState(address);
  const [hasSelection, setHasSelection] = useState(false);
  const [selectedLineOrGate, setSelectedLineOrGate] = useState({});
  const [totalPrice, setTotalPrice] = useState(null);
  const dispatch = useDispatch();
  const [chipData, setChipData] = useState(null);
  const [deleteChip, setDeleteChip] = useState(null);
  const [deleteGate, setDeleteGate] = useState(null);
  const [newGate, setNewGate] = useState(null);
  const [continueDrawingChips, setContinueDrawingChips] = useState([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isSelectFenceOpen, setIsSelectFenceOpen] = useState(false);
  // const [isAddingGate, setIsAddingGate] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [currentMode, setCurrentMode] = useState("");
  const [showTutorial, setShowTutorial] = useState(true);

  const setIsAddingGate = (value) => {
    dispatch(updateField({ field: "isAddingGate", value }));
  };

  const isAddingGate = useSelector((state) => state.customer.isAddingGate);

  const selectedFence = useSelector((state) => state.selectedFence);
  const gates = useSelector((state) => state.customer.gates);
  const features = useSelector((state) => state.customer.features);
  const customer = useSelector((state) => state.customer);

  const selectedFenceRef = useRef(selectedFence);
  const isAddingGateRef = useRef(isAddingGate);
  const newGateRef = useRef(newGate);
  const gatesRef = useRef(gates);
  const customerRef = useRef(customer);

  const geocodeAddress = async (address) => {
    try {
      const response = await geocoder
        .forwardGeocode({
          query: address,
          limit: 1,
        })
        .send();

      if (
        response &&
        response.body &&
        response.body.features &&
        response.body.features.length > 0
      ) {
        const feature = response.body.features[0];
        const [lng, lat] = feature.geometry.coordinates;

        setLng(lng);
        setLat(lat);
        map.current.flyTo({ center: [lng, lat], zoom: zoom, speed: 3 });

        // Update the marker position
        marker.current.setLngLat([lng, lat]);
      }
    } catch (error) {
      console.error("Error geocoding address:", error);
    }
  };

  useEffect(() => {
    selectedFenceRef.current = selectedFence;
  }, [selectedFence]);

  useEffect(() => {
    isAddingGateRef.current = isAddingGate;
  }, [isAddingGate]);

  useEffect(() => {
    newGateRef.current = newGate;
  }, [newGate]);

  useEffect(() => {
    gatesRef.current = gates;
  }, [gates]);

  useEffect(() => {
    customerRef.current = customer;
  }, [customer]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    geocodeAddress(address); // Replace with your desired address
  }, []);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      currentLineLength: 0,
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      center: [lng, lat],
      zoom: zoom,
      pitchWithRotate: false, // Disable rotating the map while pitching
      dragRotate: false, // Disable drag rotation
      touchZoomRotate: { rotate: false }, // Disable touch rotation
      keyboard: {
        zoom: true,
        rotate: false,
      },
    });

    // Add Azure Maps tile layer
    map.current.on("load", function () {
      map.current.addSource("azure-maps", {
        type: "raster",
        tiles: [
          `https://atlas.microsoft.com/map/tile?subscription-key=${azureMapsKey}&api-version=2024-04-01&tilesetId=microsoft.imagery&tileSize=256&zoom={z}&x={x}&y={y}`,
        ],
        tileSize: 256,
      });

      map.current.addLayer({
        id: "azure-maps-layer",
        type: "raster",
        source: "azure-maps",
        minzoom: 0,
      });
      if (draw.getAll && quoteData.data) {
        const modifiedQuoteFeatures = quoteData.data.features.map((feature) =>
          formatFeatureToMapbox(feature)
        );

        const quoteFeatureCollection = {
          features: modifiedQuoteFeatures,
          type: "FeatureCollection",
        };

        dispatch(
          updateField({
            field: "featuresFenceType",
            value: quoteData.data.featuresFenceType,
          })
        );

        dispatch(
          updateField({
            field: "gates",
            value: quoteData.data.gates,
          })
        );

        draw.add(quoteFeatureCollection);

        const data = draw.getAll();

        const newFeatures = data.features;

        dispatch(updateField({ field: "features", value: newFeatures }));
        setCurrentFeatures(newFeatures);
        if (newFeatures.length > 0) {
          setHasSelection(true);
        }
      }
    });

    map.current.addControl(draw);

    map.current.on("click", (e) => {
      console.log("click");
      if (draw.getMode() === "draw_line_string") {
        const allFeatures = draw.getAll().features;
        const lastInLine = allFeatures[allFeatures.length - 1];
        if (lastInLine) {
          map.current.currentLineLength =
            lastInLine.geometry.coordinates.length - 1;
        }
      }
      setSelectedLineOrGate({});
    });

    map.current.on("touchstart", async (e) => {
      console.log("Touchstart: ", e);

      if (isAddingGateRef.current) {
        try {
          console.log("newGate: ", newGate);

          const closeFeatures = map.current.queryRenderedFeatures([
            [e.point.x - 15, e.point.y - 15],
            [e.point.x + 15, e.point.y + 15],
          ]);

          console.log("closeFeatures: ", closeFeatures);

          if (closeFeatures.length > 0) {
            const foundFeature = closeFeatures
              .map((feature) => draw.get(feature.properties.id))
              .find((value) => value !== undefined);
            if (foundFeature) {
              const line = turf.lineString(foundFeature.geometry.coordinates);
              const cursorPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);

              var snapped = turf.nearestPointOnLine(line, cursorPoint, {
                units: "miles",
              });

              const newCreatedGate = {
                coordinates: snapped.geometry.coordinates,
              };

              const { x, y } = map.current.project([
                snapped.geometry.coordinates[0],
                snapped.geometry.coordinates[1],
              ]);
              const feature = await draw.getFeatureIdsAt({ x, y });
              const featureFoundId = feature[0];

              dispatch(
                addGate({ ...newCreatedGate, parentFenceId: featureFoundId })
              );
              setIsAddingGate(false);
              setNewGate(null);
              postFeaturesToFirebase();
            }
          }
        } catch {
          console.log("failed to set fence");
        }
      }
    });

    // Set up the mousedown event listener
    map.current.on("mousedown", async (e) => {
      console.log("addingGate: ", e);

      if (isAddingGateRef.current) {
        console.log("in here");
        map.current.handleAddGate();
      }

      setIsMouseDown(true);

      // Automatically open selectFence window
      // if (!selectedFenceRef.current.id) {
      //   setIsSelectFenceOpen(true);
      // }
    });

    // Set up the mouseup event listener
    map.current.on("mouseup", () => {
      setIsMouseDown(false);
      const mode = draw.getMode();
      if (mode === "draw_line_string") {
        popup.setHTML(
          `<div class="content finish-click">Click again to finish fence line</div>`
        );
      }
    });

    map.current.on("mousemove", function (event) {
      updateDimensions(event, selectedFenceRef.current);

      const mode = draw.getMode();

      if (mode === "simple_select") {
        // Adding Gate
        if (isAddingGateRef.current) {
          const closeFeatures = map.current.queryRenderedFeatures([
            [event.point.x - 15, event.point.y - 15],
            [event.point.x + 15, event.point.y + 15],
          ]);

          if (closeFeatures.length > 0) {
            const foundFeature = closeFeatures
              .map((feature) => draw.get(feature.properties.id))
              .find((value) => value !== undefined);
            if (foundFeature) {
              const line = turf.lineString(foundFeature.geometry.coordinates);
              const cursorPoint = turf.point([
                event.lngLat.lng,
                event.lngLat.lat,
              ]);

              var snapped = turf.nearestPointOnLine(line, cursorPoint, {
                units: "miles",
              });

              setNewGate({
                coordinates: snapped.geometry.coordinates,
              });
            } else {
              setNewGate(null);
            }
          } else {
            setNewGate(null);
          }
        }
      }

      const features = map.current.queryRenderedFeatures(event.point);

      if (features.length) {
        const hoveredFeature = features[0];

        // console.log("hoveredFeature: ", hoveredFeature);

        // const parentId = hoveredFeature.properties.parent;
        // const parentLine = draw.get(parentId);
        // console.log("parentLine: ", parentLine);
        // if (parentLine) {
        //   const pointCoords =
        //     parentLine.geometry.coordinates[
        //       hoveredFeature.properties.coord_path
        //     ];
        //     setDeleteThing(pointCoords)
        //   console.log("pointCoords: ", pointCoords);
        // }

        if (mode === "direct_select") {
          if (hoveredFeature.layer.type === "circle" && popup) {
            if (hoveredFeature.properties.meta === "vertex") {
              popup
                .setLngLat(event.lngLat)
                .setHTML(
                  `<div class="content">drag to move or click to delete</div>`
                )
                .addTo(map.current);
              return;
            } else if (hoveredFeature.properties.meta === "midpoint") {
              popup
                .setLngLat(event.lngLat)
                .setHTML(`<div class="content">click to add midpoint</div>`)
                .addTo(map.current);
              return;
            }
          }
        }

        if (mode === "draw_line_string") {
          if (hoveredFeature.layer.type === "circle" && popup) {
            popup.setHTML(
              `<div class="content finish-click">Click again to finish fence line</div>`
            );
            return;
          }
        }
      }

      if (mode === "draw_line_string") {
        const currentLength = map.current.currentLineLength;

        popup
          .setLngLat(event.lngLat)
          .setHTML(
            currentLength > 1
              ? `<div class="content">click to add point or press enter to finish</div>`
              : `<div class="content">click to add point</div>`
          )
          .addTo(map.current);
      } else {
        popup.remove();
      }
    });

    map.current.on("draw.create", (e) => {
      updateLength(e, selectedFenceRef.current);
    });
    map.current.on("draw.delete", (e) =>
      updateLength(e, selectedFenceRef.current)
    );

    map.current.on("draw.update", (e, action) => {
      const selectedFeatures = e.features;
      setDeleteChip(null);
      setDeleteGate(null);

      if (selectedFeatures) {
        setupContinueDrawingChips(selectedFeatures);
      }
      updateLength(e, selectedFenceRef.current);
    });

    map.current.on("draw.selectionchange", (e) => {
      if (draw.getMode() === "simple_select" && e.features.length > 0) {
        changeMode("direct_select", { featureId: e.features[0].id });
      }

      const selectedFeatures = e.features;
      const points = e.points;

      if (points.length > 0) {
        setDeleteChip({ coordinates: points[0].geometry.coordinates });
      } else {
        setDeleteChip(null);
      }

      setContinueDrawingChips([]);

      if (selectedFeatures.length > 0) {
        const ids = selectedFeatures.map((feature) => feature.id);

        setSelectedLineOrGate({ id: ids[0], isFeature: true });

        setupContinueDrawingChips(selectedFeatures);
      } else {
        // setSelectedLineOrGate([]);
      }
    });

    const setupContinueDrawingChips = (selectedFeatures) => {
      if (selectedFeatures.length > 0) {
        const newArray = [];
        selectedFeatures.map((feature) => {
          const coords = feature.geometry.coordinates;

          newArray.push({
            coordinates: coords[0],
            label: "Continue drawing",
            feature: feature.id,
          });
          newArray.push({
            coordinates: coords[coords.length - 1],
            label: "Continue drawing",
            feature: feature.id,
          });
        });
        setContinueDrawingChips(newArray);
      }
    };

    map.current.on("draw.modechange", (e) => {
      setCurrentMode(e.mode);
    });

    map.current.on("movestart", function (e) {
      setIsMoving(true);
    });

    map.current.on("moveend", async function (e) {
      setIsMoving(false);
      setChipData(null);
    });

    map.current.on("draw.modechange", function (e) {});

    map.current.on("error", function (e) {
      if (
        e &&
        e.error.message.includes(
          "Could not load image because of The source image could not be decoded"
        )
      ) {
        // Ignore this error
        // console.warn("Image loading error ignored:", e.error.message);
        return;
      }
    });

    // Add a marker at the initial coordinates
    marker.current = new mapboxgl.Marker()
      .setLngLat([lng, lat])
      .addTo(map.current);
    console.log("map.current: ", map.current);
  }, [lng, lat, zoom]);

  const postFeaturesToFirebase = () => {
    setTimeout(() => {
      const allFeatures = draw.getAll();

      const mapboxFeatures = allFeatures.features;

      const transformedFeatures = mapboxFeatures.map((feature) => {
        const transformedFeature = {
          ...feature,
          geometry: {
            ...feature.geometry,
            coordinates: feature.geometry.coordinates.map((coord) => ({
              lat: coord[1],
              lng: coord[0],
            })),
          },
        };
        return transformedFeature;
      });
      updateQuoteById(quoteData.id, {
        data: {
          features: transformedFeatures,
          featuresFenceType: customerRef.current.featuresFenceType,
          gates: customerRef.current.gates,
        },
      });
    }, 2000);
  };

  const changeMode = (changeModeString, options = null) => {
    map.current.currentLineLength = 0;
    popup.remove();
    if (changeModeString === "trash") {
      if (options) {
        draw.changeMode("simple_select", {
          featureIds: options,
        });
      } else {
        const selectedFeatures = draw.getSelected();
        const featureIds = selectedFeatures.features.map(
          (feature) => feature.id
        );
        draw.changeMode("simple_select", {
          featureIds: featureIds,
        });
      }
      draw.trash();
      setContinueDrawingChips(null);
      setCurrentMode("simple_select");
      postFeaturesToFirebase();
    } else {
      if (changeModeString !== "simple_select") {
        setIsAddingGate(false);
      }
      if (options) {
        draw.changeMode(changeModeString, options);
      } else {
        draw.changeMode(changeModeString);
      }
      setCurrentMode(changeModeString);
    }

    // const newMode = draw.getMode();
    // const newModeItems = ["draw_line_string", "simple_select"];
    // newModeItems.forEach((className) => {
    //   mapContainer.current.classList.remove(className);
    // });
    // mapContainer.current.classList.add(newMode);
  };

  const calculatePickets = (number_of_panels) => {
    const pickets = number_of_panels * 17;

    const roundedPickets = Math.round(pickets * 100) / 100;

    return roundedPickets;
  };

  const calculatePosts = (features, rounded_length, panel_width) => {
    let posts = 0;

    // calculate corners
    features.forEach((feature) => {
      const points = feature.geometry.coordinates.length;
      posts += points;
    });

    // calculate posts per length of fence
    const more_posts = rounded_length / panel_width;

    posts += more_posts;
    return Math.round(posts * 100) / 100;
  };

  const handleSearchAddress = (e) => {
    e.preventDefault();
    geocodeAddress(newAddress);
  };
  const handleAddressChange = (e) => {
    const value = e.target.value;

    setNewAddress(value);
  };

  const updateDimensions = (e = "null", selectedFenceState) => {
    const data = draw.getAll();

    const newFeatures = data.features;

    const quantityTotals = document.getElementById("calculated-length");
    const priceTotals = document.getElementById("calculated-price");

    if (newFeatures.length > 0) {
      let length = 0;
      const dimensions = {
        type: "FeatureCollection",
        fenceData: selectedFenceState,
        features: [],
      };

      newFeatures.forEach((feature) => {
        const segment = turf.length(feature, { units: "feet" });

        const coordinates = feature.geometry.coordinates;

        const lengths = [];

        if (coordinates.length > 1) {
          for (let index = 0; index < coordinates.length - 1; index++) {
            const from = turf.point(coordinates[index]);
            const to = turf.point(coordinates[index + 1]);

            var options = { units: "feet" };

            var distance = turf.distance(from, to, options);
            lengths.push(distance);

            var bothPoints = turf.points([
              coordinates[index],
              coordinates[index + 1],
            ]);

            var center = turf.center(bothPoints);

            dimensions.features.push({
              type: "Feature",
              properties: {
                description: `${roundNumber(distance)}${
                  settings.lengthUnitAbr
                }`,
              },
              geometry: {
                type: "Point",
                coordinates: center.geometry.coordinates,
              },
            });
          }
        }

        length += segment;
      });

      const currentDimensions = map.current.getSource("dimensions");
      if (currentDimensions) {
        map.current.getSource("dimensions").setData(dimensions);
      } else {
        map.current.addSource("dimensions", {
          type: "geojson",
          data: dimensions,
        });

        map.current.addLayer({
          id: "poi-labels",
          type: "symbol",
          source: "dimensions",
          layout: {
            "text-field": ["get", "description"],
            "text-variable-anchor": ["top", "bottom", "left", "right"],
            "text-radial-offset": 0.5,
            "text-justify": "auto",
            "icon-image": ["get", "icon"],
            "text-size": 22,
            "text-offset": [0, 0.6],
          },
          paint: {
            "text-color": "black",
            "text-halo-color": "#ffffff",
            "text-halo-width": 2,
          },
        });
      }

      const panel_width = 8;
      const rounded_length = Math.round(length * 100) / 100;
      const number_of_panels = Math.round((length / panel_width) * 100) / 100;
      const totalPosts = calculatePosts(
        newFeatures,
        rounded_length,
        panel_width
      );
      const totalPickets = calculatePickets(number_of_panels);
      const totalStringers = number_of_panels * 3;

      quantityTotals.innerHTML = `
        <h2>Quantitites</h2>
        <p><strong>Posts: <strong> ${totalPosts} </p>
        <p><strong>Whole Panels: <strong> ${number_of_panels} </p>
        <p><strong>Pickets: <strong> ${totalPickets} </p>
        <p><strong>Nails: </strong>${rounded_length}</p>
        <p><strong>Feet of fence: </strong>${rounded_length}</p>
        `;

      const postPrice = 4;
      const picketPrice = 2.5;
      const stringerPrice = 2.25;

      const totalPostPrice = roundNumber(totalPosts * postPrice);
      const totalPicketPrice = roundNumber(totalPickets * picketPrice);
      const totalStringerPrice = roundNumber(totalStringers * stringerPrice);

      const totalOverallPrice = roundNumber(
        totalPicketPrice + totalPostPrice + totalStringerPrice
      );

      setTotalPrice(totalOverallPrice);
      priceTotals.innerHTML = `
        <h2>Price:</h2>
        <p><strong>Posts: </strong>$${totalPostPrice}</p>
        <p><strong>Pickets: </strong>$${totalPicketPrice}</p>
        <p><strong>Stringers: </strong>$${totalStringerPrice}</p>
        
        <h3>Total: $${totalOverallPrice}</h3> 
        `;
    }
  };

  const updateLength = (e = "null", selectedFenceState) => {
    const data = draw.getAll();

    const newFeatures = data.features;

    if (e.type === "draw.create") {
      dispatch(
        setFeatureFenceType({
          id: e.features[0].id,
          fenceType: selectedFenceState,
        })
      );
    }

    setCurrentFeatures(newFeatures);
    dispatch(updateField({ field: "features", value: newFeatures }));

    if (newFeatures.length > 0) {
      updateDimensions(e, selectedFence.current);
      setHasSelection(true);
    } else {
      clearAll();
    }

    postFeaturesToFirebase();
  };

  const clearAll = () => {
    const quantityTotals = document.getElementById("calculated-length");
    const priceTotals = document.getElementById("calculated-price");
    draw.deleteAll();

    quantityTotals.innerHTML = "";
    priceTotals.innerHTML = "";
    map.current.getSource("dimensions").setData({
      type: "FeatureCollection",
      features: [],
    });
    setHasSelection(false);
    setCurrentFeatures([]);
    setContinueDrawingChips([]);
    setDeleteChip(null);
    dispatch(updateField({ field: "gates", value: [] }));
    dispatch(updateField({ field: "features", value: [] }));
    setTotalPrice(null);
    postFeaturesToFirebase();
  };

  var popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    offset: 15,
  });

  // const isWithinMapBounds = (lng, lat) => {
  //   const mapBounds = map.current.getBounds();

  //   const [northWestLng, northWestLat] = [
  //     mapBounds.getWest(),
  //     mapBounds.getNorth(),
  //   ];
  //   const [southEastLng, southEastLat] = [
  //     mapBounds.getEast(),
  //     mapBounds.getSouth(),
  //   ];

  //   const withinBounds =
  //     lng >= northWestLng &&
  //     lng <= southEastLng &&
  //     lat >= southEastLat &&
  //     lat <= northWestLat;

  //   if (withinBounds) {
  //     return true;
  //   } else {
  //     return false; // Or handle as needed, e.g., hide the icon
  //   }
  // };

  const projectToScreenCoordinates = (lng, lat) => {
    if (!map.current) return [0, 0];

    const { x, y } = map.current.project([lng, lat]);

    const isWithinBounds = isWithinMapBounds(lng, lat, map);

    if (!isWithinBounds) return null;

    return [x, y];
  };

  const continueDrawing = (chipCoordinates, featureId) => {
    // draw.changeMode("draw_line_string", {
    //   featureId: featureId,
    //   from: chipCoordinates,
    // });
    changeMode("draw_line_string", {
      featureId: featureId,
      from: chipCoordinates,
    });
    setContinueDrawingChips(null);
    setDeleteChip(null);
  };

  const deleteChipPositioning = deleteChip
    ? projectToScreenCoordinates(
        deleteChip.coordinates[0],
        deleteChip.coordinates[1]
      )
    : [0, 0];

  const gateTooltipPositioning = newGate
    ? projectToScreenCoordinates(newGate.coordinates[0], newGate.coordinates[1])
    : [0, 0];

  const handleAddGate = () => {
    if (!newGate) return;

    const findAt = {
      x: gateTooltipPositioning[0],
      y: gateTooltipPositioning[1],
    };

    const featureFoundId = draw.getFeatureIdsAt(findAt)[0];
    console.log("findAt: ", findAt);
    console.log("featureFoundId: ", featureFoundId);
    console.log("newGate: ", newGate);

    dispatch(addGate({ ...newGate, parentFenceId: featureFoundId }));
    setIsAddingGate(false);
    setNewGate(null);
    postFeaturesToFirebase();
  };

  if (map.current) map.current.handleAddGate = handleAddGate;

  const handleRemoveGate = (id) => {
    dispatch(removeGate(id));
  };

  const getAlertContent = () => {
    if (currentMode === "simple_select") {
      return false;
    }

    if (currentMode === "direct_select") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Drag points, or click <ControlPointIcon style={{ margin: "0 5" }} />{" "}
          to add more points
        </div>
      );
    }

    if (currentMode === "draw_line_string") {
      return "Click to add a point, click twice to finish drawing";
    }

    return currentMode;
  };

  const shouldShowIcon = (lng, lat) => {
    isWithinMapBounds(lng, lat, map);
  };

  return (
    <div>
      {(features.length > 0 || gates.length > 0) && (
        <Paper
          sx={{
            maxHeight: "calc(100% - 100px)",
            overflowY: "auto",
            maxWidth: "calc(100vw - 70px)",
          }}
          id="customer-box"
          elevation={3}
        >
          <DimensionList
            postFeaturesToFirebase={postFeaturesToFirebase}
            changeMode={changeMode}
            map={map}
            getFeatureLength={getFeatureLength}
            settings={settings}
            data={currentFeatures}
            selectedLineOrGate={selectedLineOrGate}
            setSelectedLineOrGate={setSelectedLineOrGate}
            draw={draw}
            totalPrice={totalPrice}
            deleteAll={clearAll}
            hasSelection={hasSelection}
          />
        </Paper>
      )}

      <Paper style={{ display: "none" }} id="details-box" elevation={3}>
        <div id="calculated-length"></div>
        <div id="calculated-price"></div>
      </Paper>
      <div ref={mapContainer} className="map-container" />
      <ButtonBox
        currentMode={currentMode}
        isAddingGate={isAddingGate}
        setIsAddingGate={setIsAddingGate}
        setIsSelectFenceOpen={setIsSelectFenceOpen}
        map={map}
        changeMode={changeMode}
      />
      <ChooseFenceDialog
        changeMode={changeMode}
        open={isSelectFenceOpen}
        setOpen={setIsSelectFenceOpen}
        fenceTypeList={fenceTypeList}
      />

      {deleteChip && !isMouseDown && !isMoving && (
        <Tooltip title="delete point">
          <Fab
            size="small"
            color="error"
            aria-label="delete chip"
            // key={`${deleteChip.feature}-${deleteChip.coordinates[0]}-${deleteChip.coordinates[1]}`}
            coords={deleteChip.coordinates}
            // feature-id={deleteChip.feature}
            label="delete it"
            onClick={draw.trash}
            // onClick={() =>
            //   continueDrawing(deleteChip.coordinates, deleteChip.feature)
            // }
            // onClick={() => continueDrawing(chip.coordinates)}
            style={{
              position: "absolute",
              left: `${deleteChipPositioning[0] - 40}px`,
              top: `${deleteChipPositioning[1] - 40}px`,
              scale: "0.75",
            }}
          >
            <CancelOutlinedIcon />
          </Fab>
        </Tooltip>
      )}

      {newGate && isAddingGate && (
        <Fab
          size="small"
          color="warning"
          aria-label="continue draw"
          key={`${newGate.feature}-${newGate.coordinates[0]}-${newGate.coordinates[1]}`}
          coords={newGate.coordinates}
          feature-id={newGate.feature}
          label={newGate.label}
          // onClick={() => continueDrawing(chip.coordinates)}
          style={{
            pointerEvents: "none",
            position: "absolute",
            left: `${gateTooltipPositioning[0] - 20}px`,
            top: `${gateTooltipPositioning[1] - 20}px`,
            scale: "1",
          }}
        >
          <GateIcon style={{ fill: "white", width: 28, height: 28 }} />
        </Fab>
      )}

      {gates &&
        !isMouseDown &&
        !isMoving &&
        gates.map((gate) => {
          const gatePositioning =
            projectToScreenCoordinates(
              gate.coordinates[0],
              gate.coordinates[1]
            ) || false;

          if (!gatePositioning) return null;

          return (
            // <Tooltip
            //   placement="bottom"
            //   variant="solid"
            //   style={{ background: "transparent" }}
            //   key={`${gate.feature}-${gate.coordinates[0]}-${gate.coordinates[1]}`}
            //   title={
            //     <>
            //       <Chip
            //         label="Delete gate"
            //         style={{ background: "white" }}
            //         variant="plain"
            //         size="small"
            //         onDelete={() => handleRemoveGate(gate.id)}
            //       />
            //     </>
            //   }
            // >
            <Fab
              onClick={() => {
                setSelectedLineOrGate({ id: gate.id, isFeature: false });
                changeMode("simple_select", { featureIds: [] });
              }}
              size="small"
              color={selectedLineOrGate.id !== gate.id ? "warning" : "success"}
              aria-label="continue draw"
              coords={gate.coordinates}
              feature-id={gate.feature}
              label={gate.label}
              style={{
                position: "absolute",
                left: `${gatePositioning[0] - 20}px`,
                top: `${gatePositioning[1] - 20}px`,
                scale: "0.75",
                transition:
                  "outline 0.3s ease-in-out, outline-offset 0.3s ease-in-out",
                outline:
                  selectedLineOrGate.id !== gate.id ? "" : "4px solid #dfdfdf",
                outlineOffset: selectedLineOrGate.id !== gate.id ? "" : "4px",
              }}
            >
              <GateIcon style={{ fill: "white", width: 28, height: 28 }} />
            </Fab>
            // </Tooltip>
          );
        })}

      {continueDrawingChips &&
        !isMoving &&
        !isMouseDown &&
        continueDrawingChips.map((chip, index) => {
          const chipPositioning =
            projectToScreenCoordinates(
              chip.coordinates[0],
              chip.coordinates[1]
            ) || false;

          if (!chipPositioning) return null;

          return (
            <Tooltip
              key={`${chip.feature}-${chip.coordinates[0]}-${chip.coordinates[1]}`}
              title="continue drawing"
              placement="left"
              variant="solid"
            >
              <Fab
                size="small"
                color="primary"
                aria-label="continue draw"
                coords={chip.coordinates}
                feature-id={chip.feature}
                label={chip.label}
                onClick={() => continueDrawing(chip.coordinates, chip.feature)}
                // onClick={() => continueDrawing(chip.coordinates)}
                style={{
                  position: "absolute",
                  left: `${chipPositioning[0]}px`,
                  top: `${chipPositioning[1]}px`,
                  scale: "0.75",
                }}
              >
                <ControlPointIcon />
              </Fab>
            </Tooltip>

            // <Chip
            // />
          );
        })}
      {currentMode && currentMode !== "simple_select" && (
        <div
          style={{
            position: "absolute",
            bottom: 10,
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Alert style={{ width: "fit-content" }} open severity="info">
            {getAlertContent()}
          </Alert>
        </div>
      )}

      <TutorialVideoDialog
        setShowTutorial={setShowTutorial}
        showTutorial={showTutorial}
      />
      <ChooseGateModal
        changeMode={changeMode}
        setIsAddingGate={setIsAddingGate}
      />
      <div style={{ position: "absolute", bottom: "35px", left: "5px" }}>
        <Tooltip title="Show Help Video" placement="left" variant="solid">
          <Fab
            size="medium"
            onClick={() => setShowTutorial(true)}
            aria-label="open help menu"
          >
            <HelpOutlineIcon />
          </Fab>
        </Tooltip>
      </div>
    </div>
  );
};

export default MapPage;
