import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ModeIcon from "@mui/icons-material/Mode";
import PanToolIcon from "@mui/icons-material/PanTool";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import "./index.css";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import { Fence } from "@mui/icons-material";
import Box from "@mui/material/Box";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Tooltip from "@mui/joy/Tooltip";
import { ReactComponent as GateIcon } from "../../assets/images/GateIcon.svg";

import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../../utils/customerSlice";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ButtonBox = ({
  changeMode,
  map,
  setIsSelectFenceOpen,
  setIsAddingGate,
  currentMode,
}) => {
  const dispatch = useDispatch();
  const isAddingGate = useSelector((state) => state.customer.isAddingGate);
  const company = useSelector((state) => state.customer.company);
  const [isSmallWidth, setIsSmallWidth] = useState(false);

  // Function to handle window resize
  const handleResize = (e) => {
    if (e.target.document.body.getBoundingClientRect().width < 650) {
      setIsSmallWidth(true);
    } else {
      setIsSmallWidth(false);
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    window.addEventListener("resize", handleResize);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); //

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setIsAddingGate(false);
        changeMode("simple_select");
      }
    };

    // Attach the event listener
    document.addEventListener("keydown", handleEsc);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const handleDrawClick = () => {
    // changeMode("draw_line_string");
    setIsSelectFenceOpen(true);
  };

  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenGateModal = () => {
    dispatch(updateField({ field: "isGateModalOpen", value: true }));
  };

  function isMobileOrEmulatedDevice() {
    // Check for mobile user agent
    const isMobile =
      /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    // Check if it's running in Chrome DevTools mobile emulation by seeing if touch events are enabled and width is smaller than desktop
    const isEmulatedMobile =
      window.innerWidth <= 650 &&
      "ontouchstart" in window &&
      navigator.userAgent.includes("Chrome");

    return isMobile || isEmulatedMobile;
  }

  if (company) {
    return (
      <Box className="main-container">
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Drawer
            anchor="right"
            variant="permanent"
            open={!isSmallWidth && !isMobileOrEmulatedDevice() && open}
          >
            <List>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={handleDrawClick}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    background:
                      currentMode === "draw_line_string" ? "#1976d236" : "",
                    borderRadius: "6px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ModeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Create fence"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              {company.catalogGateMap && (
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    onClick={() => {
                      handleOpenGateModal();
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      background: isAddingGate ? "#1976d236" : "",
                      borderRadius: "6px",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <GateIcon
                        style={{ fill: "#0000008a", width: 28, height: 28 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Add gate"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => {
                    setIsAddingGate(false);
                    changeMode("simple_select");
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    background:
                      (currentMode === "simple_select" ||
                        currentMode === "direct_select") &&
                      !isAddingGate
                        ? "#1976d236"
                        : "",
                    borderRadius: "6px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PanToolIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Selection mode"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <Divider style={{ margin: "4px 0" }} />
              {/* <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => changeMode("trash")}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
  
                    borderRadius: "6px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Delete selected fence"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem> */}

              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => map.current.zoomIn()}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,

                    borderRadius: "6px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ZoomInIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Zoom in"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => map.current.zoomOut()}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    borderRadius: "6px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ZoomOutIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Zoom out"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>

            <div
              style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "flex-end",
                height: "100%",
              }}
            >
              <Divider />
              {!isSmallWidth && !isMobileOrEmulatedDevice() && (
                <DrawerFooter>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    onClick={open ? handleDrawerClose : handleDrawerOpen}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </ListItemIcon>
                    <ListItemText
                      primary="Collapse panel"
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </DrawerFooter>
              )}
            </div>
          </Drawer>
        </Box>
      </Box>
    );
  }
};

export default ButtonBox;
