import { httpsCallable } from "firebase/functions";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { auth, functions } from "./FireStore";

import axios from "axios";

// const functions = getFunctions();
const helloWorld = httpsCallable(functions, "testHelloWorld");

export const sendEmail = ({
  toEmail,
  text,
  emailContent,
  subject,
  fromEmail = "admin@fencelink.app",
  fromName = "FenceLink",
}) => {
  const sendEmail = httpsCallable(functions, "sendEmailCall");
  sendEmail({
    toEmail: toEmail,
    text: text,
    emailContent: emailContent,
    subject: subject,
    fromEmail,
    fromName,
  })
    .then((result) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      console.log("result: ", result);
      const data = result.data;
      const sanitizedMessage = data.text;
      return data;
    })
    .catch((error) => {
      console.log("error: ", error);
      // Getting the Error details.
      const code = error.code;
      const message = error.message;
      const details = error.details;
      return error;
    });
};

export const addStripeCustomer = async (adminUserId, companyData) => {
  // const { companyName, companyEmail, companyPhone, companyAddress } = companyData;

  // create a promise on the next line
  return new Promise((resolve, reject) => {
    // do something async

    const addstripecustomer = httpsCallable(functions, "addstripecustomer");

    addstripecustomer({
      companyData: companyData,
      adminUserId: adminUserId,
    })
      .then((result) => {
        // Process the result if needed
        console.log("result: ", result);
        const data = result.data;
        resolve(data);
        // return data; // Return data to the next .then() in the chain
      })
      .catch((error) => {
        console.log("Error creating company with stripe details: ", error);
        // Rethrow the error to be caught by the caller's .catch()
        // throw error;
        reject(error);
      });
  });
};
