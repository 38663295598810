import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Drawer from "@mui/joy/Drawer";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Checkbox from "@mui/joy/Checkbox";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import ModalClose from "@mui/joy/ModalClose";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Stack from "@mui/joy/Stack";
import RadioGroup from "@mui/joy/RadioGroup";
import Radio from "@mui/joy/Radio";
import Sheet from "@mui/joy/Sheet";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import TuneIcon from "@mui/icons-material/TuneRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import MeetingRoomRoundedIcon from "@mui/icons-material/MeetingRoomRounded";
import HotelRoundedIcon from "@mui/icons-material/HotelRounded";
import Done from "@mui/icons-material/Done";
import { useSelector, useDispatch } from "react-redux";
import { companySlice, addStyleToCatalog } from "../../utils/companySlice";
import Add from "@mui/icons-material/Add";

export default function ChooseSelectionDrawer({
  buttonText,
  list,
  buttonProps,
  buttonSize,
  map,
  addToCatalog,
  selected,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("Guesthouse");
  const [amenities, setAmenities] = React.useState([0, 6]);

  const allItemsAreSelected = () => {
    let allItems = true;
    list.forEach((item) => {
      const foundItem = map ? map[item] : item;
      if (!selected.includes(foundItem.id)) {
        allItems = false;
      }
    });
    return allItems;
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Button
          sx={{ ...buttonProps }}
          variant="plain"
          color="primary"
          startDecorator={<Add />}
          onClick={() => setOpen(true)}
          size={buttonSize || "sm"}
        >
          {buttonText}
        </Button>
      </Box>
      <Drawer
        size="md"
        variant="plain"
        open={open}
        anchor="right"
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              p: { md: 3, sm: 0 },
              boxShadow: "none",
            },
          },
        }}
      >
        <Sheet
          sx={{
            borderRadius: "md",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <DialogTitle>Manage Catalog</DialogTitle>
          <ModalClose />
          <Divider sx={{ mt: "auto" }} />
          <DialogContent sx={{ gap: 2 }}>
            <FormControl>
              <FormLabel sx={{ typography: "title-md", fontWeight: "bold" }}>
                Add {buttonText} to Catalog
              </FormLabel>
              {/* <RadioGroup
                value={type || ""}
                onChange={(event) => {
                  setType(event.target.value);
                }}
              > */}
            </FormControl>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(140px, 1fr))",
                gap: 1.5,
              }}
            >
              {allItemsAreSelected() && (
                <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
                  No items left to display
                </Typography>
              )}
              {!allItemsAreSelected() &&
                list.map((item) => {
                  //   console.log("item: ", item);
                  //   console.log("optionText: ", optionText);
                  // if (currentSelected.includes(map ? map[item].id : item.id)) return null;
                  //   if (optionText && map ? map[item].option : item.option !== optionText) return null;
                  const foundItem = map ? map[item] : item;

                  if (selected && selected.includes(foundItem.id)) return null;
                  return (
                    <Card
                      key={foundItem.name}
                      sx={{
                        boxShadow: "none",
                        "&:hover": { bgcolor: "background.level1" },
                      }}
                    >
                      <CardContent
                        sx={{ display: "flex", flexDirection: "row" }}
                      >
                        <img
                          style={{ height: 50, width: 50 }}
                          alt={foundItem.name}
                          src={(map ? map[item].imageUrl : item.imageUrl) || ""}
                        />

                        <Typography level="title-md">
                          {foundItem.name}
                        </Typography>
                      </CardContent>
                      <Checkbox
                        onClick={() => {
                          console.log("foundItem: ", foundItem);
                          dispatch(addToCatalog({ id: foundItem.id }));
                        }}
                        disableIcon
                        overlay
                        checked={type === item.name}
                        variant="outlined"
                        color="neutral"
                        value={foundItem.name}
                        sx={{ mt: -2 }}
                        slotProps={{
                          action: {
                            sx: {
                              ...(type === foundItem.name && {
                                borderWidth: 2,
                                borderColor:
                                  "var(--joy-palette-primary-outlinedBorder)",
                              }),
                              "&:hover": {
                                bgcolor: "transparent",
                              },
                            },
                          },
                        }}
                      />
                    </Card>
                  );
                })}
            </Box>
            {/* </RadioGroup> */}

            {/* <Typography level="title-md" sx={{ fontWeight: "bold", mt: 1 }}>
              Amenities
            </Typography>
            <div role="group" aria-labelledby="rank">
              <List
                orientation="horizontal"
                size="sm"
                sx={{ "--List-gap": "12px", "--ListItem-radius": "20px" }}
              >
                {["Wi-fi", "Washer", "A/C", "Kitchen"].map((item, index) => {
                  const selected = amenities.includes(index);
                  return (
                    <ListItem key={item}>
                      <AspectRatio
                        variant={selected ? "solid" : "outlined"}
                        color={selected ? "primary" : "neutral"}
                        ratio={1}
                        sx={{ width: 20, borderRadius: 20, ml: -0.5, mr: 0.75 }}
                      >
                        <div>{selected && <Done fontSize="md" />}</div>
                      </AspectRatio>
                      <Checkbox
                        size="sm"
                        color="neutral"
                        disableIcon
                        overlay
                        label={item}
                        variant="outlined"
                        checked={selected}
                        onChange={(event) =>
                          setAmenities((prev) => {
                            const set = new Set([...prev, index]);
                            if (!event.target.checked) {
                              set.delete(index);
                            }

                            return [...set];
                          })
                        }
                        slotProps={{
                          action: {
                            sx: {
                              "&:hover": {
                                bgcolor: "transparent",
                              },
                            },
                          },
                        }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>

            <Typography level="title-md" sx={{ fontWeight: "bold", mt: 2 }}>
              Booking options
            </Typography>
            <FormControl orientation="horizontal">
              <Box sx={{ flex: 1, pr: 1 }}>
                <FormLabel sx={{ typography: "title-sm" }}>
                  Instant booking
                </FormLabel>
                <FormHelperText sx={{ typography: "body-sm" }}>
                  Listings that you can book without waiting for host approval.
                </FormHelperText>
              </Box>
              <Switch />
            </FormControl>

            <FormControl orientation="horizontal">
              <Box sx={{ flex: 1, mt: 1, mr: 1 }}>
                <FormLabel sx={{ typography: "title-sm" }}>
                  Self check-in
                </FormLabel>
                <FormHelperText sx={{ typography: "body-sm" }}>
                  Easy access to the property when you arrive.
                </FormHelperText>
              </Box>
              <Switch />
            </FormControl> */}
          </DialogContent>

          <Divider sx={{ mt: "auto" }} />
          <Stack
            direction="row"
            useFlexGap
            spacing={1}
            sx={{ justifyContent: "flex-end" }}
          >
            {/* <Button
              variant="outlined"
              color="neutral"
              onClick={() => {
                setType("");
                setAmenities([]);
              }}
            >
              close
            </Button> */}
            <Button onClick={() => setOpen(false)}>done</Button>
          </Stack>
        </Sheet>
      </Drawer>
    </React.Fragment>
  );
}
