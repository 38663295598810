import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import BookmarkAdd from "@mui/icons-material/BookmarkAddOutlined";
import { setSelectedFence, updateField } from "../../utils/customerSlice";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/joy";

export default function FenceStyleCard({ data, type }) {
  const dispatch = useDispatch();
  const selectedFence = useSelector((state) => state.customer.selectedFence);
  const company = useSelector((state) => state.customer.company);
  const catalogMap = company ? company.catalogMap : {};
  const globalItemsMap = useSelector((state) => state.company.globalItemsMap);
  const globalAttributesMap = useSelector(
    (state) => state.company.globalAttributesMap
  );

  const handleSelectStyle = (itemId, remove = false) => {
    switch (type) {
      case "style":
        dispatch(setSelectedFence({ style: data }));
        break;

      case "material":
        dispatch(
          setSelectedFence({ style: selectedFence.style, material: data })
        );
        break;

      case "attribute":
        if (remove) {
          console.log("REMOVE TRUE");
          // remove item from attributesMap
          const newAttributesMap = { ...selectedFence.attributesMap };
          delete newAttributesMap[data.id];

          dispatch(
            setSelectedFence({
              style: selectedFence.style,
              material: selectedFence.material,
              attributesMap: newAttributesMap,
            })
          );
        } else {
          dispatch(
            setSelectedFence({
              style: selectedFence.style,
              material: selectedFence.material,
              attributesMap: {
                ...selectedFence.attributesMap,

                [data.id]: {
                  attribute: data,
                  id: data.id,
                  item: globalItemsMap[itemId],
                },
              },
            })
          );
        }
        break;

      default:
        break;
    }
  };

  return (
    <Card
      sx={
        type === "attribute"
          ? {
              width: "100%",
            }
          : {
              width: {
                xs: "calc(100% - 1rem)",
                sm: "calc(50% - 1rem)",
                md: "calc(33.3333% - 1rem)",
                lg: "calc(25% - 1rem)",
              },
              maxWidth: "400px",
            }
      }
    >
      <div>
        <Typography level="title-lg">{data.name}</Typography>
        {data.type && (
          <Typography level="body-sm">type: {data.type}</Typography>
        )}
        <IconButton
          aria-label="bookmark fence type"
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ position: "absolute", top: "0.875rem", right: "0.5rem" }}
        >
          <BookmarkAdd />
        </IconButton>
      </div>
      {data.imageUrl && (
        <AspectRatio minHeight="120px" maxHeight="200px">
          <img src={data.imageUrl} loading="lazy" alt="" />
        </AspectRatio>
      )}
      <CardContent orientation="horizontal">
        <div>
          {data.totalPrice && (
            <Typography level="body-xs">Total price:</Typography>
          )}
        </div>
        {type === "attribute" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
              gap: 1,
            }}
          >
            {catalogMap[selectedFence.style.id].materialsMap[
              selectedFence.material.id
            ].attributesMap[data.id].itemsList.map((itemId) => {
              return (
                <Button
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 100,
                  }}
                  variant={
                    selectedFence.attributesMap &&
                    selectedFence.attributesMap[data.id] &&
                    itemId === selectedFence.attributesMap[data.id].item.id
                      ? "solid"
                      : "outlined"
                  }
                  onClick={() =>
                    handleSelectStyle(
                      itemId,
                      selectedFence.attributesMap &&
                        selectedFence.attributesMap[data.id] &&
                        itemId === selectedFence.attributesMap[data.id].item.id
                    )
                  }
                >
                  <span> {globalItemsMap[itemId].name}</span>
                  <span>
                    $
                    {
                      catalogMap[selectedFence.style.id].materialsMap[
                        selectedFence.material.id
                      ].attributesMap[data.id].itemsMap[itemId].price
                    }
                  </span>
                </Button>
              );
            })}
          </Box>
        )}
        {type !== "attribute" && (
          <Button
            onClick={handleSelectStyle}
            variant="solid"
            size="md"
            color="primary"
            aria-label="select attribute"
            sx={{ ml: "auto", alignSelf: "center", fontWeight: 600 }}
          >
            Select
          </Button>
        )}
      </CardContent>
    </Card>
  );
}
