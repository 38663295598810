import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Provider } from "react-redux";
import { store } from "./utils/store";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import MapPage from "./pages/MapPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import CustomerInfoPage from "./pages/CustomerInfoPage";
import DashboardPage from "./pages/DashboardPage";
import CreateCompanyPage from "./pages/CreateCompanyPage";
import NotFoundPage from "./pages/NotFoundPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import MapPageContainer from "./pages/MapPageContainer";
import ProtectedRoute from "./ProtectedRoute";
import AppWrapper from "./components/AppWrapper";
import ThankYouPage from "./pages/ThankYouPage";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppWrapper>
          <div>
            <Routes>
              {/* <Route path="/" element={<HomePage />} /> */}
              <Route exact path="/" element={<SignInPage />} />
              <Route exact path="/sign-in" element={<SignInPage />} />
              <Route exact path="/sign-up" element={<SignUpPage />} />
              <Route path="/completed/:quoteId" element={<ThankYouPage />} />
              <Route path="/quote/:companyId" element={<CustomerInfoPage />} />
              <Route
                exact
                path="/fence-map/:quoteId/:address"
                element={<MapPageContainer />}
              />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPasswordPage />}
              />
              <Route
                exact
                path="/create-company"
                element={
                  <ProtectedRoute>
                    <CreateCompanyPage />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </AppWrapper>
      </Router>
    </Provider>
  );
}

export default App;
