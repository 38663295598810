import React, { useState } from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import CardMedia from "@mui/material/CardMedia";
import settings from "../../utils/settings.json";
import Button from "@mui/joy/Button";
import Box from "@mui/material/Box";

export default function TutorialVideoDialog({ showTutorial, setShowTutorial }) {
  const [acceptedTutorial, setAcceptedTutorial] = useState(false);
  return (
    <Modal
      aria-labelledby="tutorial video on fence draft"
      aria-describedby="tutorial video on using fence draft"
      open={showTutorial}
      //   onClose={() => setShowTutorial(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 3000,
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 800,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose
          onClick={() => setShowTutorial(false)}
          variant="plain"
          sx={{ m: 1 }}
        />

        {!acceptedTutorial && (
          <div style={{ display: "flex", gap: 12, flexFlow: "column" }}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Welcome to Fence Draft!
            </Typography>
            <Typography id="modal-desc" textColor="text.tertiary">
              Would you like to watch a 1 minute tutorial on how to use{" "}
              {settings.companyName}?
            </Typography>
            <div
              style={{
                justifyContent: "flex-end",
                display: "flex",
                gap: 6,
                marginTop: 12,
              }}
            >
              <Button onClick={() => setAcceptedTutorial(true)}>
                Watch Tutorial Video
              </Button>
              <Button onClick={() => setShowTutorial(false)} variant="outlined">
                Skip video
              </Button>
            </div>
          </div>
        )}

        <br></br>
        {acceptedTutorial && (
          <Box sx={{ padding: 4 }}>
            <CardMedia
              component="iframe"
              controls
              src="https://www.youtube.com/embed/UDvfCLlpixw"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        )}
      </Sheet>
    </Modal>
  );
}
