/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { ColorPaletteProp } from "@mui/joy/styles";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import BlockIcon from "@mui/icons-material/Block";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { updateQuoteById, deleteQuoteById } from "../../utils/api/quote";
import { useDispatch, useSelector } from "react-redux";
import Switch from "@mui/joy/Switch";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import ChooseSelectionDrawer from "./ChooseSelectionDrawer";
import {
  addCompanyTypeId,
  pushOptionToMap,
  addCompanyStyle,
  addCompanyMaterial,
  addCompanyAttribute,
  addCompanyAttributeItem,
  addStyleToCatalog,
  addMaterialToCatalog,
  addAttributeToCatalog,
  addItemToCatalog,
  updateCatalogPrice,
  removeStyleFromCatalog,
} from "../../utils/companySlice";
import { newGlobalTypes, newGlobalTypesMap } from "../../utils/newGlobalData";
import { updateCompanyById } from "../../utils/api/company";
import companyCatalog from "../../mockData/companyCatalog.json";
import Card from "./Card";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function RowMenu({ id, isDeleted }) {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem>Edit</MenuItem>
        <MenuItem>Rename</MenuItem>
        <MenuItem
          onClick={() => {
            updateQuoteById(id, { isDeleted: false });
          }}
        >
          Restore
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            if (isDeleted) {
              deleteQuoteById(id);
            } else {
              updateQuoteById(id, { isDeleted: true });
            }
          }}
          color="danger"
        >
          {isDeleted ? "Delete Forever" : "Delete"}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}

export default function CatalogTable() {
  const dispatch = useDispatch();

  const companyId = useSelector((state) => state.company.id);

  const globalStylesList = useSelector(
    (state) => state.company.globalStylesList
  );
  const globalStylesMap = useSelector((state) => state.company.globalStylesMap);
  const globalAttributesList = useSelector(
    (state) => state.company.globalAttributesList
  );
  const globalAttributesMap = useSelector(
    (state) => state.company.globalAttributesMap
  );
  const globalMaterialsList = useSelector(
    (state) => state.company.globalMaterialsList
  );
  const globalMaterialsMap = useSelector(
    (state) => state.company.globalMaterialsMap
  );
  const globalItemsList = useSelector((state) => state.company.globalItemsList);
  const globalItemsMap = useSelector((state) => state.company.globalItemsMap);

  const catalogList = useSelector((state) => state.company.catalogList);
  const catalogMap = useSelector((state) => state.company.catalogMap);

  // const rows = companyCatalog.styles;
  const rows = catalogList;
  console.log("Rows: ", rows);

  const [showDeletedQuotes, setShowDeletedQuotes] = useState(false);
  const [order, setOrder] = useState("desc");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [priceMap, setPriceMap] = useState({});

  const handleAddCompanyCatalog = () => {
    //update company catalog
    updateCompanyById(companyId, { catalogMap: catalogMap });
  };

  const handlePriceChange = (e) => {
    const inputName = e.target.name;
    setPriceMap({ ...priceMap, [inputName]: e.target.value });

    const price = e.target.value;
  };

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <Select
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          <Option value="paid">Paid</Option>
          <Option value="pending">Pending</Option>
          <Option value="refunded">Refunded</Option>
          <Option value="cancelled">Cancelled</Option>
          <Option value="Completed">Completed</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Category</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">All</Option>
          <Option value="refund">Refund</Option>
          <Option value="purchase">Purchase</Option>
          <Option value="debit">Debit</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Customer</FormLabel>
        <Select size="sm" placeholder="All">
          <Option value="all">All</Option>
          <Option value="olivia">Olivia Rhye</Option>
          <Option value="steve">Steve Hampton</Option>
          <Option value="ciaran">Ciaran Murray</Option>
          <Option value="marina">Marina Macdonald</Option>
          <Option value="charles">Charles Fulton</Option>
          <Option value="jay">Jay Hoper</Option>
        </Select>
      </FormControl>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <ChooseSelectionDrawer
        buttonText="style"
        list={globalStylesList}
        addToCatalog={({ id }) => addStyleToCatalog({ styleId: id })}
        selected={catalogList.map((id) => id)}
      />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {catalogList.map((styleId) => {
          console.log("ROWWWW: ", catalogMap[styleId]);
          return (
            <Card
              removeStyleFromCatalog={removeStyleFromCatalog}
              styleId={styleId}
              data={globalStylesMap[styleId]}
              addMaterialToCatalog={addMaterialToCatalog}
              addAttributeToCatalog={addAttributeToCatalog}
              addItemToCatalog={addItemToCatalog}
              updateCatalogPrice={updateCatalogPrice}
              globalMaterialsMap={globalMaterialsMap}
              globalStylesMap={globalStylesMap}
              globalAttributesMap={globalAttributesMap}
              catalogList={catalogList}
              catalogMap={catalogMap}
              globalItemsMap={globalItemsMap}
            />
          );
        })}
      </Box>
    </React.Fragment>
  );
}
