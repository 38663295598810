import Chip from "@mui/joy/Chip";
import Tooltip from "@mui/joy/Tooltip";
import ChipDelete from "@mui/joy/ChipDelete";

export default function GateChip({ children, deleteFunction, id }) {
  return (
    <Tooltip variant="outlined" title={id && id}>
      <Chip
        variant="outlined"
        endDecorator={
          deleteFunction && <ChipDelete onDelete={deleteFunction} />
        }
      >
        {children}
      </Chip>
    </Tooltip>
  );
}
