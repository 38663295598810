import React, { useState, useEffect } from "react";
import _ from "lodash";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import IconButton from "@mui/joy/IconButton";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import LinearProgress from "@mui/joy/LinearProgress";
import CircularProgress from "@mui/joy/CircularProgress";
import PricingCard from "../../components/PricingCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CardContent from "@mui/joy/CardContent";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { auth, storage, functions } from "../../utils/FireStore";
import { signOut } from "firebase/auth";
import { getCompanyById, updateCompanyById } from "../../utils/api/company";
import { setCompanyData } from "../../utils/companySlice";
import { getUserCompanyId } from "../../utils/api/user";
import * as globalSlice from "../../utils/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../utils/FireStore";
import { httpsCallable } from "firebase/functions";

// import DropZone from "./DropZone";
// import FileUpload from "./FileUpload";
// import CountrySelector from "./CountrySelector";
// import EditorToolbar from "./EditorToolbar";

export default function CompanyPage() {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const companyInfo = {
    companyAddress: company["companyAddress"] || "",
    companyName: company["companyName"] || "",
    companyPhone: company["companyPhone"] || "",
    companyWebsite: company["companyWebsite"] || "",
    companyEmail: company["companyEmail"] || "",
    companyLogo: company["companyLogo"] || "",
  };

  const [newCompanyInfo, setNewCompanyInfo] = useState(companyInfo);
  const [openNewLogoModal, setOpenNewLogoModal] = useState(false);
  const [image, setImage] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [companySubscription, setCompanySubscription] = useState({});
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCompanyInfo({ ...newCompanyInfo, [name]: value });
  };

  const getSubscriptions = async () => {
    const subsList = [];
    // Create a reference to the "subscriptions" collection
    const subsRef = collection(db, "subscriptions");

    // Create a query against the collection
    const q = query(subsRef, where("active", "==", true));

    // Execute the query
    const querySnapshot = await getDocs(q);

    for (const doc of querySnapshot.docs) {
      console.log(doc.id, " => ", doc.data());

      // Get the "prices" subcollection
      const priceSnap = await getDocs(collection(doc.ref, "prices"));

      for (const priceDoc of priceSnap.docs) {
        console.log(priceDoc.id, " => ", priceDoc.data());
        subsList.push({
          productId: doc.id,
          priceId: priceDoc.id,
          ...doc.data(),
          price: priceDoc.data(),
        });
      }
    }
    console.log("subsList: ", subsList);
    setSubscriptions(subsList);
  };
  useEffect(() => {
    getSubscriptions();
    getCurrentSubscription();
  }, []);

  const [preview, setPreview] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setPreview(URL.createObjectURL(file));
      handleUpload(file);
    }
    // setOpenNewLogoModal(false);
  };

  const handleUpdateCompanyInfo = async () => {
    const formattedCompanyInfo = { ...newCompanyInfo };
    if (formattedCompanyInfo.companyWebsite) {
      function formatWebsite(url) {
        return /^(https?:\/\/)/.test(url) ? url : `https://${url}`;
      }

      const formattedWebsite = formatWebsite(newCompanyInfo.companyWebsite);
      formattedCompanyInfo.companyWebsite = formattedWebsite;
    }
    updateCompanyById(company.id, formattedCompanyInfo)
      .then((res) => {
        dispatch(setCompanyData(formattedCompanyInfo));
        dispatch(
          globalSlice.updateFields({
            snackbarText: "Company info updated",
            snackbarOpen: true,
            snackbarColor: "success",
          })
        );
      })
      .catch((err) => alert("Error updating company info: ", err));
  };

  const handleUpload = (image) => {
    if (image) {
      const storageRef = ref(storage, `images/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Progress function
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          // Error function
          console.error("Upload failed", error);
        },
        () => {
          // Complete function
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // setUrl(downloadURL);
            setNewCompanyInfo({ ...newCompanyInfo, companyLogo: downloadURL });
            console.log("File available at", downloadURL);
            setOpenNewLogoModal(false);
            setProgress(0);
          });
        }
      );
    }
  };

  const companyInfoIsEqual = () => {
    return _.isEqual(companyInfo, newCompanyInfo);
  };

  const signOutHandler = () => {
    signOut(auth);
  };

  const onClickViewSubscription = async () => {
    const currentUser = auth.currentUser;

    if (currentUser) {
      console.log("Preparing to create portal link...");
      const createPortalLink = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );

      try {
        const functionRef = createPortalLink;

        const { data } = await functionRef({
          returnUrl: window.location.origin + "/dashboard",
          locale: "auto", // Optional, defaults to "auto"
          // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional configuration ID
        });

        console.log("Portal link data: ", data);

        if (data.url) {
          // Redirect to the portal URL
          window.location.assign(data.url);
        } else {
          console.error("No URL returned from the portal link function.");
        }
      } catch (error) {
        console.error("Error creating portal link: ", error);
        alert(`An error occurred: ${error.message}`);
      }
    } else {
      // Handle the case where the user is not authenticated
      console.error("No user is signed in.");
    }
  };

  const onClickSubscription = async (priceId) => {
    const currentUser = auth.currentUser;

    console.log("company: ", company);
    console.log("priceId: ", priceId);
    if (currentUser) {
      setLoadingCheckout(true);
      // Create a reference to the customer's checkout_sessions subcollection
      const checkoutSessionsRef = collection(
        doc(collection(db, "customers"), company.id),
        "checkout_sessions"
      );

      // Add a new checkout session document
      const docRef = await addDoc(checkoutSessionsRef, {
        price: priceId,
        success_url: window.location.origin + "/dashboard",
        cancel_url: window.location.origin + "/dashboard",
      });

      // Listen for updates to the checkout session document
      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        console.log("snap.data: ", snap.data);
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          setLoadingCheckout(false);
          alert(`An error occurred: ${error.message}`);
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.
          setLoadingCheckout(false);
          window.location.assign(url);
        }
      });
    } else {
      // Handle the case where the user is not authenticated

      console.error("No user is signed in.");
    }
  };

  const getCurrentSubscription = async () => {
    try {
      // Initialize Firestore

      // Reference the 'subscriptions' collection under the specific customer
      const subscriptionsRef = collection(
        db,
        `customers/${company.id}/subscriptions`
      );

      // Fetch all documents in the 'subscriptions' sub-collection
      const querySnapshot = await getDocs(subscriptionsRef);

      // Extract subscription data
      const subscriptionsList = querySnapshot.docs.map((doc) => doc.data());

      // Check the first subscription's status
      if (subscriptionsList.length > 0) {
        let status = "inactive";
        subscriptionsList.forEach((sub) => {
          console.log("sub: ", sub);
          if (sub.status === "active" || sub.status === "trialing") {
            status = sub.status;
          }
        });
        // const status = subscriptionsList[0].status;
        setCompanySubscription({ status: status });
        return status === "active";
      }

      // Handle case where no subscriptions are found
      setCompanySubscription({ status: null });
      return false;
    } catch (error) {
      console.error("Error fetching subscription status:", error);
      setCompanySubscription({ status: null });
      return false;
    }
    // try {
    //   console.log("company.id: ", company.id);
    //   const customerDocRef = doc(db, "customers", company.id);
    //   console.log("customerDocRef: ", customerDocRef);
    //   const subscriptionsRef = collection(customerDocRef, "subscriptions");
    //   const q = query(
    //     subscriptionsRef,
    //     where("status", "in", ["trialing", "active"])
    //   );

    //   const querySnapshot = await getDocs(q);
    //   console.log("querySnapshot: ", querySnapshot);
    //   const subscriptionData = await querySnapshot.docs[0].data();

    //   console.log("subscriptionData: ", subscriptionData);
    //   console.log("subscriptionData.status: ", subscriptionData.status);

    //   setCompanySubscription(subscriptionData);
    // } catch (error) {
    //   console.log("Error getting subscription: ", error);
    //   setCompanySubscription({});
    // }

    // db.collection("customers")
    // .doc(currentUser.uid)
    // .collection("subscriptions")
    // .where("status", "in", ["trialing", "active"])
    // .onSnapshot(async (snapshot) => {
    // In this implementation we only expect one active or trialing subscription to exist.
    // const doc = snapshot.docs[0];
    // console.log(doc.id, " => ", doc.data());
    // });
  };

  console.log("subscriptions: ", subscriptions);
  return (
    <>
      <Modal
        aria-labelledby="upload logo"
        aria-describedby="upload new logo icon"
        open={openNewLogoModal}
        onClose={() => {
          setOpenNewLogoModal(false);
        }}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{ maxWidth: 500, borderRadius: "md", p: 3, boxShadow: "lg" }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            sx={{ fontWeight: "lg", mb: 1 }}
          >
            Upload Logo
          </Typography>
          {progress === 0 && (
            <input type="file" accept="image/*" onChange={handleFileChange} />
          )}
          {progress > 0 && <LinearProgress determinate value={progress} />}
        </Sheet>
      </Modal>
      <Box sx={{ flex: 1, width: "100%" }}>
        <Box
          sx={{
            position: "sticky",
            top: { sm: -100, md: -110 },
            bgcolor: "background.body",
            zIndex: 9995,
          }}
        ></Box>
        <Stack
          spacing={4}
          sx={{
            display: "flex",
            maxWidth: "800px",
            mx: "auto",
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">Company info</Typography>
              <Typography level="body-sm">
                Customize how your company information will apper to the public.
              </Typography>
            </Box>
            <Divider />
            <Stack
              direction="row"
              spacing={3}
              sx={{ display: { xs: "none", md: "flex" }, my: 1 }}
            >
              <Stack direction="column" spacing={1}>
                <AspectRatio
                  ratio="1"
                  maxHeight={200}
                  sx={{ flex: 1, minWidth: 120, borderRadius: "100%" }}
                >
                  <img
                    src={preview || newCompanyInfo.companyLogo}
                    loading="lazy"
                    alt=""
                  />
                </AspectRatio>
                <IconButton
                  onClick={() => setOpenNewLogoModal(true)}
                  aria-label="upload new picture"
                  size="sm"
                  variant="outlined"
                  color="neutral"
                  sx={{
                    bgcolor: "background.body",
                    position: "absolute",
                    zIndex: 2,
                    borderRadius: "50%",
                    left: 100,
                    top: 170,
                    boxShadow: "sm",
                  }}
                >
                  <EditRoundedIcon />
                </IconButton>
              </Stack>
              <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Stack
                  spacing={1}
                  sx={{
                    display: { sm: "flex-row", md: "flex-row" },
                    gap: 2,
                  }}
                >
                  <FormControl>
                    <FormLabel>Company Name</FormLabel>
                    <Input
                      onChange={handleInputChange}
                      value={newCompanyInfo.companyName}
                      name="companyName"
                      size="sm"
                      placeholder="Company Name"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input
                      onChange={handleInputChange}
                      value={newCompanyInfo.companyPhone}
                      name="companyPhone"
                      size="sm"
                      placeholder="Company Phone"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      onChange={handleInputChange}
                      value={newCompanyInfo.companyEmail}
                      name="companyEmail"
                      size="sm"
                      placeholder="Company Email"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Address</FormLabel>
                    <Input
                      onChange={handleInputChange}
                      value={newCompanyInfo.companyAddress}
                      name="companyAddress"
                      size="sm"
                      placeholder="Company Address"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Website</FormLabel>
                    <Input
                      onChange={handleInputChange}
                      value={newCompanyInfo.companyWebsite}
                      name="companyWebsite"
                      size="sm"
                      placeholder="Company Website"
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              sx={{ display: { xs: "flex", md: "none" }, my: 1 }}
            >
              <Stack direction="row" spacing={2}>
                <Stack direction="column" spacing={1}>
                  <AspectRatio
                    ratio="1"
                    maxHeight={108}
                    sx={{ flex: 1, minWidth: 108, borderRadius: "100%" }}
                  >
                    <img
                      src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
                      srcSet="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286&dpr=2 2x"
                      loading="lazy"
                      alt=""
                    />
                  </AspectRatio>
                  <IconButton
                    aria-label="upload new picture"
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    sx={{
                      bgcolor: "background.body",
                      position: "absolute",
                      zIndex: 2,
                      borderRadius: "50%",
                      left: 85,
                      top: 180,
                      boxShadow: "sm",
                    }}
                  >
                    <EditRoundedIcon />
                  </IconButton>
                </Stack>
                <Stack spacing={1} sx={{ flexGrow: 1 }}>
                  <FormControl
                    sx={{
                      display: {
                        sm: "flex-column",
                        md: "flex-row",
                      },
                      gap: 2,
                    }}
                  >
                    <FormLabel>Name</FormLabel>
                    <Input size="sm" placeholder="First name" />
                  </FormControl>
                </Stack>
                <FormControl
                  sx={{
                    display: {
                      sm: "flex-column",
                      md: "flex-row",
                    },
                    gap: 2,
                  }}
                >
                  <FormLabel>Last name</FormLabel>
                  <Input size="sm" placeholder="Last name" />
                </FormControl>
              </Stack>
              <FormControl>
                <FormLabel>Role</FormLabel>
                <Input size="sm" defaultValue="UI Developer" />
              </FormControl>
              <FormControl sx={{ flexGrow: 1 }}>
                <FormLabel>Email</FormLabel>
                <Input
                  size="sm"
                  type="email"
                  startDecorator={<EmailRoundedIcon />}
                  placeholder="email"
                  defaultValue="siriwatk@test.com"
                  sx={{ flexGrow: 1 }}
                />
              </FormControl>
              <div>{/* <CountrySelector /> */}</div>
              <div>
                <FormControl sx={{ display: { sm: "contents" } }}>
                  <FormLabel>Timezone</FormLabel>
                  <Select
                    size="sm"
                    startDecorator={<AccessTimeFilledRoundedIcon />}
                    defaultValue="1"
                  >
                    <Option value="1">
                      Indochina Time (Bangkok){" "}
                      <Typography textColor="text.tertiary" ml={0.5}>
                        — GMT+07:00
                      </Typography>
                    </Option>
                    <Option value="2">
                      Indochina Time (Ho Chi Minh City){" "}
                      <Typography textColor="text.tertiary" ml={0.5}>
                        — GMT+07:00
                      </Typography>
                    </Option>
                  </Select>
                </FormControl>
              </div>
            </Stack>
            <CardOverflow
              sx={{ borderTop: "1px solid", borderColor: "divider" }}
            >
              <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                <Button
                  onClick={() => {
                    setPreview(null);
                    setNewCompanyInfo(companyInfo);
                  }}
                  size="sm"
                  variant="outlined"
                  color="neutral"
                >
                  Cancel
                </Button>
                <Button
                  disabled={companyInfoIsEqual()}
                  size="sm"
                  variant="solid"
                  onClick={handleUpdateCompanyInfo}
                >
                  Save
                </Button>
              </CardActions>
            </CardOverflow>

            {companySubscription.status !== "active" ? (
              <>
                <h1>Become a subscriber:</h1>
                {subscriptions.map((sub) => (
                  <PricingCard
                    onClickFunc={() => onClickSubscription(sub.priceId)}
                    price={sub.price.unit_amount}
                    name={
                      sub.metadata.isTest ? `${sub.name} - TEST` : `${sub.name}`
                    }
                  />
                ))}
              </>
            ) : (
              <>
                <h1>Subscription:</h1>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Card
                    data-resizable
                    sx={{
                      textAlign: "center",
                      alignItems: "center",
                      width: 343,
                      // to make the demo resizable
                      overflow: "auto",
                      resize: "horizontal",
                      "--icon-size": "100px",
                    }}
                  >
                    <CardOverflow variant="solid" color="primary">
                      <AspectRatio
                        variant="outlined"
                        color="warning"
                        ratio="1"
                        sx={{
                          m: "auto",
                          transform: "translateY(50%)",
                          borderRadius: "50%",
                          width: "var(--icon-size)",
                          boxShadow: "sm",
                          bgcolor: "background.surface",
                          position: "relative",
                        }}
                      >
                        <div>
                          <CheckCircleIcon
                            color="primary"
                            sx={{ fontSize: "4rem" }}
                          />
                        </div>
                      </AspectRatio>
                    </CardOverflow>
                    <Typography
                      level="title-lg"
                      sx={{ mt: "calc(var(--icon-size) / 2)" }}
                    >
                      {companySubscription.items &&
                        companySubscription.items[0].price.product.name.toUpperCase()}
                    </Typography>
                    <CardContent sx={{ maxWidth: "40ch" }}>
                      Your subscription is {companySubscription.status}.
                    </CardContent>
                    <CardActions
                      orientation="vertical"
                      buttonFlex={1}
                      sx={{
                        "--Button-radius": "40px",
                        width: "clamp(min(100%, 160px), 50%, min(100%, 200px))",
                      }}
                    >
                      {/* <Button variant="solid" color="primary">
                      Share
                    </Button> */}
                      <Button
                        onClick={onClickViewSubscription}
                        variant="plain"
                        color="neutral"
                      >
                        Edit Subscription
                      </Button>
                    </CardActions>
                  </Card>
                </Box>
              </>
            )}
          </Card>

          {/* <Button onClick={signOutHandler}>Sign Out</Button> */}
        </Stack>
      </Box>
    </>
  );
}
