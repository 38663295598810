import { configureStore } from "@reduxjs/toolkit";
import selectedFence from "../components/ChooseFenceDialog/chooseFenceSlice";
import customer from "./customerSlice";
import companySlice from "./companySlice";
import globalSlice from "./globalSlice";
import userSlice from "./userSlice";

export const store = configureStore({
  reducer: {
    selectedFence: selectedFence,
    customer: customer,
    company: companySlice,
    global: globalSlice,
    user: userSlice,
  },
});
