/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { ColorPaletteProp } from "@mui/joy/styles";
import Box from "@mui/joy/Box";
import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";
import Link from "@mui/joy/Link";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListDivider from "@mui/joy/ListDivider";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Switch from "@mui/joy/Switch";

import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import BlockIcon from "@mui/icons-material/Block";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { updateQuoteById, deleteQuoteById } from "../../utils/api/quote";

function RowMenu({ id, isDeleted }) {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: "plain", color: "neutral", size: "sm" } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        {/* <MenuItem>Edit</MenuItem>
        <MenuItem>Rename</MenuItem>
        <MenuItem>Move</MenuItem> */}
        {isDeleted && (
          <MenuItem
            onClick={() => {
              updateQuoteById(id, { isDeleted: false });
            }}
          >
            Restore
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          onClick={() => {
            if (isDeleted) {
              deleteQuoteById(id);
            } else {
              updateQuoteById(id, { isDeleted: true });
            }
          }}
          color="danger"
        >
          {isDeleted ? "Delete Forever" : "Delete"}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}

export default function OrderList({ listItems }) {
  const [showDeletedQuotes, setShowDeletedQuotes] = React.useState(false);
  return (
    <Box sx={{ display: { xs: "block", sm: "none" } }}>
      <FormControl size="sm">
        <FormLabel>Show Deleted Quotes</FormLabel>
        <div style={{ display: "flex" }}>
          <Switch
            onChange={() => setShowDeletedQuotes(!showDeletedQuotes)}
            checked={showDeletedQuotes}
            sx={{
              marginLeft: "20px",
              "--Switch-thumbSize": "14px",
              "--Switch-trackWidth": "40px",
              "--Switch-trackHeight": "20px",
            }}
          />
        </div>
      </FormControl>
      {listItems.map((listItem) => {
        if (listItem.isDeleted && !showDeletedQuotes) return null;
        if (!listItem.isDeleted && showDeletedQuotes) return null;
        return (
          <List
            key={listItem.id}
            size="sm"
            sx={{
              "--ListItem-paddingX": 0,
              backgroundColor: listItem.isDeleted ? "#c900007d" : "",
            }}
          >
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <ListItemContent
                sx={{ display: "flex", gap: 2, alignItems: "start" }}
              >
                <ListItemDecorator>
                  <Avatar size="sm">{listItem.name.charAt(0)}</Avatar>
                </ListItemDecorator>
                <div>
                  <Typography fontWeight={600} gutterBottom>
                    {listItem.name}
                  </Typography>
                  <Typography level="body-xs" gutterBottom>
                    {listItem.email}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 0.5,
                      mb: 1,
                    }}
                  >
                    <Typography level="body-xs">
                      {/* {listItem.createdAt.toDate()} */}
                      {`${new Date(
                        listItem.createdAt
                      ).toLocaleDateString()} @ ${new Date(
                        listItem.createdAt
                      ).toLocaleTimeString()}`}
                    </Typography>
                    <Typography level="body-xs">&bull;</Typography>
                    <Typography
                      sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      level="body-xs"
                    >
                      {listItem.id}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <Link
                      // disabled={listItem.status !== "completed"}
                      onClick={() =>
                        window.open(
                          `/fence-map/${listItem.id}/${listItem.address}`
                        )
                      }
                      level="body-xs"
                      component="button"
                    >
                      View Customer Fence Draft
                    </Link>
                    <RowMenu isDeleted={listItem.isDeleted} id={listItem.id} />
                  </Box>
                </div>
              </ListItemContent>
              <Chip
                variant="soft"
                size="sm"
                startDecorator={
                  {
                    Paid: <CheckRoundedIcon />,
                    Refunded: <AutorenewRoundedIcon />,
                    Cancelled: <BlockIcon />,
                    completed: <CheckRoundedIcon />,
                  }[listItem.status]
                }
                color={
                  {
                    Paid: "success",
                    Refunded: "neutral",
                    Cancelled: "danger",
                    completed: "success",
                  }[listItem.status]
                }
              >
                {listItem.status}
              </Chip>
            </ListItem>
            <ListDivider />
          </List>
        );
      })}
      <Box
        className="Pagination-mobile"
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
          py: 2,
        }}
      >
        <IconButton
          aria-label="previous page"
          variant="outlined"
          color="neutral"
          size="sm"
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography level="body-sm" mx="auto">
          Page 1 of 10
        </Typography>
        <IconButton
          aria-label="next page"
          variant="outlined"
          color="neutral"
          size="sm"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
