import React, { useEffect, useRef, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import styles from "./index.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { masterFenceData } from "../../utils/globalFenceData";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DeleteForever from "@mui/icons-material/DeleteForever";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { removeGate, removeFeatureFenceType } from "../../utils/customerSlice";
import ChooseFenceDialog from "../ChooseFenceDialog";
import Box from "@mui/joy/Box";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import CheckoutPage from "../../components/CheckoutPage";
import {
  isWithinMapBounds,
  getGatePrice,
  getTotalQuotePrice,
  areItemsInCatalog,
  checkAllFeaturesForCatalogItems,
  areGateItemsInCatalog,
  checkAllFeaturesForGateCatalogItems,
} from "../../utils/helperFunctions";

const DimensionList = ({
  data,
  getFeatureLength,
  settings,
  selectedLineId,
  setSelectedLineOrGate,
  map,
  changeMode,
  selectedLineOrGate,
  postFeaturesToFirebase,
  hasSelection,
  draw,
  totalPrice,
  clearAll,
}) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([1]);
  const features = useSelector((state) => state.customer.features);
  const gates = useSelector((state) => state.customer.gates);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [modifyFenceType, setModifyFenceType] = useState(false);
  const [grandTotalPrice, setGrandTotalPrice] = useState(0);

  const featuresFenceType = useSelector(
    (state) => state.customer.featuresFenceType
  );
  const globalStylesMap = useSelector((state) => state.company.globalStylesMap);
  const globalAttributesMap = useSelector(
    (state) => state.company.globalAttributesMap
  );

  const company = useSelector((state) => state.customer.company);
  const catalogMap = company ? company.catalogMap : null;
  const listItemRefs = useRef([]);
  const catalogGateMap = company ? company.catalogGateMap : {};
  const globalGateAttributesMap = useSelector(
    (state) => state.company.globalGateAttributesMap
  );

  // const gatesAndFeatures = [...features];

  useEffect(() => {
    getTotalQuotePrice(
      features,
      featuresFenceType,
      catalogMap,
      gates,
      catalogGateMap
    );
  }, []);

  useEffect(() => {
    const id = selectedLineOrGate.id;
    // get the index of the id in the list gatesAndFeatures

    const focusElement = document.querySelector(`#dimension-list-${id}`);
    if (focusElement) {
      focusElement.scrollIntoViewIfNeeded({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedLineOrGate]);

  // gatesAndFeatures.forEach((_, index) => {
  //   listItemRefs.current[index] =
  //     listItemRefs.current[index] || React.createRef();
  // });

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleItemClick = (id, coordinates, isFeature = true) => {
    // draw.changeMode("simple_select", { featureIds: [id] });
    // const feature = e.features[0];
    const isInBounds = isWithinMapBounds(coordinates[0], coordinates[1], map);
    console.log("isInBounds: ", isInBounds);

    !isInBounds &&
      map.current.flyTo({ center: coordinates, zoom: 19, speed: 1 });

    setSelectedLineOrGate({ id: id, isFeature: isFeature });
    isFeature
      ? changeMode("direct_select", { featureId: id })
      : changeMode("simple_select", { featureIds: [] });
  };

  const renderBorderColor = (feature) => {
    const itemsAreInCatalog = areItemsInCatalog(
      catalogMap,
      featuresFenceType,
      feature.id
    );

    const itemsAreInGateCatalog = areGateItemsInCatalog(
      catalogGateMap,
      gates,
      feature.id
    );

    if (feature.type !== "gate") {
      if (!itemsAreInCatalog) return "solid 2px #c41c1c !important";
    } else {
      if (!itemsAreInGateCatalog) return "solid 2px #c41c1c !important";
    }

    if (selectedLineOrGate.id === feature.id) {
      if (deleteModalOpen) {
        return "solid 2px #c41c1c !important";
      } else {
        return "solid 2px black";
      }
    } else {
      return "solid 2px transparent";
    }
  };

  if (features.length < 1 || !featuresFenceType || !globalStylesMap) {
    return <div>loading...</div>;
  }

  return (
    <>
      <AccordionGroup>
        <Accordion defaultExpanded>
          <AccordionDetails>
            {" "}
            <List
              dense
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {featuresFenceType &&
                company &&
                globalStylesMap &&
                features.map((feature, index) => {
                  const labelId = `checkbox-list-secondary-label-${feature.id}`;

                  if (
                    feature.type === "Feature" &&
                    featuresFenceType[feature.id]
                  ) {
                    let totalPrice = 0;

                    Object.keys(
                      featuresFenceType[feature.id].attributesMap
                    ).map((attributeId) => {
                      const styleId = featuresFenceType[feature.id].style.id;
                      const materialId =
                        featuresFenceType[feature.id].material.id;
                      const itemId =
                        featuresFenceType[feature.id].attributesMap[attributeId]
                          .item.id;

                      const hasStyle = catalogMap[styleId];
                      const hasMaterial = hasStyle
                        ? hasStyle.materialsMap[materialId]
                        : null;
                      const hasAttribute = hasMaterial
                        ? hasMaterial.attributesMap[attributeId]
                        : null;
                      const hasItem = hasAttribute
                        ? hasAttribute.itemsMap[itemId]
                        : null;
                      const hasPrice = hasItem ? hasItem.price : null;

                      let price = 0;

                      if (
                        hasStyle &&
                        hasMaterial &&
                        hasAttribute &&
                        hasItem &&
                        hasPrice
                      ) {
                        price =
                          catalogMap[styleId].materialsMap[materialId]
                            .attributesMap[attributeId].itemsMap[itemId].price;
                      }

                      const priceToNumber = Number(price);

                      totalPrice += priceToNumber;
                    });

                    const fenceStyle =
                      globalStylesMap[featuresFenceType[feature.id].style.id];
                    const styleId = featuresFenceType[feature.id].style.id;
                    const materialId =
                      featuresFenceType[feature.id].material.id;

                    if (fenceStyle) {
                      return (
                        <>
                          {index !== 0 ? (
                            <Divider
                              style={{ margin: "5px 0" }}
                              variant="inset"
                              component="li"
                            />
                          ) : (
                            ""
                          )}

                          <ListItem
                            onClick={(e) =>
                              handleItemClick(
                                feature.id,
                                feature.geometry.coordinates[0]
                              )
                            }
                            // secondaryAction={
                            //   <div style={{ display: "flex", flexDirection: "column" }}>
                            //     <IconButton
                            //       onClick={() => {
                            //         setModifyFenceType(true);
                            //       }}
                            //       edge="end"
                            //       aria-label="delete"
                            //     >
                            //       <EditIcon />
                            //     </IconButton>
                            //     <IconButton
                            //       onClick={(e) => {
                            //         setDeleteModalOpen(true);
                            //       }}
                            //       edge="end"
                            //       aria-label="delete"
                            //     >
                            //       <DeleteIcon />
                            //     </IconButton>
                            //   </div>
                            // }
                            id={`dimension-list-${feature.id}`}
                            style={{
                              margin: 0,
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            alignItems="flex-start"
                            key={feature.id}
                            // secondaryAction={
                            //   <Checkbox
                            //     edge="end"
                            //     onChange={handleToggle(feature.id)}
                            //     checked={checked.indexOf(feature.id) !== -1}
                            //     inputProps={{ "aria-labelledby": labelId }}
                            //   />
                            // }
                            disablePadding
                          >
                            <ListItemButton
                              sx={{
                                border: renderBorderColor(feature),
                                "&:hover": {
                                  backgroundColor: "#eef8ee",
                                },
                              }}
                              className={` ${styles.dimensionItem} 
                      }`}
                              style={{
                                paddingLeft: 0,
                                borderRadius: 5,
                                backgroundColor: "unset",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexFlow: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                  width: 85,
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: catalogMap[styleId]
                                      ? "text.primary"
                                      : "red",
                                  }}
                                >
                                  {featuresFenceType[feature.id] &&
                                    // catalogMap[featuresFenceType[feature.id].style.id] &&
                                    fenceStyle.name}
                                </Typography>
                                <img
                                  style={{ width: "60px", height: "60px" }}
                                  src={
                                    // catalogMap[featuresFenceType[feature.id].style.id] &&
                                    fenceStyle.imageUrl
                                  }
                                  alt={
                                    // catalogMap[featuresFenceType[feature.id].style.id] &&
                                    fenceStyle.name
                                  }
                                  loading="lazy"
                                />
                                {/* <Avatar
                            style={{ borderRadius: 3 }}
                            alt={`fence: test`}
                            src={featuresFenceType[feature.id].type.imageUrl}
                          /> */}
                              </div>
                              <ListItemText
                                id={labelId}
                                // primary={`Line item ${feature.id + 1}`}
                                primary={
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        sx={{
                                          color: "text.primary",
                                          display: "inline",
                                        }}
                                      >
                                        <strong>Length:</strong>
                                      </Typography>
                                      {`${getFeatureLength(feature)} ${
                                        settings.lengthUnitAbr
                                      }`}
                                    </div>

                                    {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{
                                    color: "text.primary",
                                    display: "inline",
                                  }}
                                >
                                  <strong>Fence Cost:</strong>
                                </Typography>
                                {`${getFeatureLength(feature) * catalogMap[styleId].materialsMap[
                                            materialId
                                          ].attributesMap[attributeId].itemsMap[
                                            itemId
                                          ].price }`}
                                </div> */}
                                  </div>
                                }
                                secondary={
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        sx={{
                                          color:
                                            catalogMap[styleId] &&
                                            catalogMap[styleId].materialsMap[
                                              materialId
                                            ]
                                              ? "text.primary"
                                              : "red",
                                          display: "inline",
                                        }}
                                      >
                                        <strong>Material:</strong>
                                      </Typography>

                                      {catalogMap[styleId] &&
                                      catalogMap[styleId].materialsMap[
                                        materialId
                                      ] ? (
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          sx={{
                                            color: "text.primary",
                                            display: "inline",
                                          }}
                                        >
                                          {
                                            featuresFenceType[feature.id]
                                              .material.name
                                          }
                                        </Typography>
                                      ) : (
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          sx={{
                                            color: "red",
                                            display: "inline",
                                          }}
                                        >
                                          {
                                            featuresFenceType[feature.id]
                                              .material.name
                                          }
                                        </Typography>
                                      )}
                                    </div>

                                    <div>
                                      {featuresFenceType[feature.id]
                                        .attributesMap
                                        ? Object.keys(
                                            featuresFenceType[feature.id]
                                              .attributesMap
                                          ).map((attributeId) => {
                                            const styleId =
                                              featuresFenceType[feature.id]
                                                .style.id;
                                            const materialId =
                                              featuresFenceType[feature.id]
                                                .material.id;
                                            const itemId =
                                              featuresFenceType[feature.id]
                                                .attributesMap[attributeId].item
                                                .id;

                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <Typography
                                                  component="span"
                                                  variant="body2"
                                                  sx={{
                                                    color:
                                                      catalogMap[styleId] &&
                                                      catalogMap[styleId]
                                                        .materialsMap &&
                                                      catalogMap[styleId]
                                                        .materialsMap[
                                                        materialId
                                                      ] &&
                                                      catalogMap[styleId]
                                                        .materialsMap[
                                                        materialId
                                                      ].attributesMap[
                                                        attributeId
                                                      ]
                                                        ? "text.primary"
                                                        : "red",
                                                    display: "inline",
                                                  }}
                                                >
                                                  <strong>
                                                    {
                                                      globalAttributesMap[
                                                        attributeId
                                                      ].name
                                                    }
                                                    :{" "}
                                                  </strong>
                                                </Typography>

                                                {catalogMap[styleId] &&
                                                catalogMap[styleId]
                                                  .materialsMap[materialId] &&
                                                catalogMap[styleId]
                                                  .materialsMap[materialId]
                                                  .attributesMap[attributeId] &&
                                                catalogMap[styleId]
                                                  .materialsMap[materialId]
                                                  .attributesMap[attributeId]
                                                  .itemsMap[itemId] ? (
                                                  <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={{
                                                      color: "text.primary",
                                                      display: "inline",
                                                    }}
                                                  >
                                                    {
                                                      featuresFenceType[
                                                        feature.id
                                                      ].attributesMap[
                                                        attributeId
                                                      ].item.name
                                                    }{" "}
                                                    - $
                                                    {catalogMap[styleId]
                                                      .materialsMap[materialId]
                                                      .attributesMap[
                                                      attributeId
                                                    ].itemsMap[itemId].price ||
                                                      " "}{" "}
                                                    per ft.
                                                  </Typography>
                                                ) : (
                                                  <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={{
                                                      color: "red",
                                                      display: "inline",
                                                    }}
                                                  >
                                                    {
                                                      featuresFenceType[
                                                        feature.id
                                                      ].attributesMap[
                                                        attributeId
                                                      ].item.name
                                                    }
                                                  </Typography>
                                                )}
                                              </div>
                                            );
                                          })
                                        : "No Attributes"}
                                      <div>
                                        Fence price: $
                                        {totalPrice *
                                          Math.floor(getFeatureLength(feature))}
                                      </div>
                                    </div>
                                  </>
                                }
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  margin: "0 6px",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    setModifyFenceType(true);
                                  }}
                                  edge="end"
                                  aria-label="delete"
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={(e) => {
                                    setDeleteModalOpen(true);
                                  }}
                                  edge="end"
                                  aria-label="delete"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </ListItemButton>
                          </ListItem>
                          {featuresFenceType[feature.id].gates &&
                            featuresFenceType[feature.id].gates.map(
                              (gate, index) => {
                                const gateIndex = gates.findIndex(
                                  (g) => g.id === gate
                                );
                                return (
                                  <>
                                    {index !== 0 ? (
                                      <Divider
                                        style={{ margin: "5px 0" }}
                                        variant="inset"
                                        component="li"
                                      />
                                    ) : (
                                      ""
                                    )}

                                    <ListItem
                                      // sx={{  }}
                                      id={`dimension-list-${gates[gateIndex].id}`}
                                      // secondaryAction={
                                      //   <div
                                      //     style={{
                                      //       display: "flex",
                                      //       flexDirection: "column",
                                      //     }}
                                      //   >
                                      //     <IconButton
                                      //       disabled
                                      //       edge="end"
                                      //       aria-label="delete"
                                      //     >
                                      //       <EditIcon />
                                      //     </IconButton>
                                      //     <IconButton
                                      //       onClick={(e) => {
                                      //         setDeleteModalOpen(true);
                                      //       }}
                                      //       edge="end"
                                      //       aria-label="delete"
                                      //     >
                                      //       <DeleteIcon />
                                      //     </IconButton>
                                      //   </div>
                                      // }
                                      ref={listItemRefs.current[index]}
                                      style={{
                                        maxWidth: "calc(100% - 45px)",
                                        marginLeft: "45px",
                                      }}
                                      alignItems="flex-start"
                                      onClick={() =>
                                        handleItemClick(
                                          gates[gateIndex].id,
                                          gates[gateIndex].coordinates,
                                          false
                                        )
                                      }
                                      key={gates[gateIndex].id}
                                      // secondaryAction={
                                      //   <Checkbox
                                      //     edge="end"
                                      //     onChange={handleToggle(feature.id)}
                                      //     checked={checked.indexOf(feature.id) !== -1}
                                      //     inputProps={{ "aria-labelledby": labelId }}
                                      //   />
                                      // }
                                      disablePadding
                                    >
                                      <ListItemButton
                                        sx={{
                                          border: renderBorderColor(
                                            gates[gateIndex]
                                          ),
                                          "&:hover": {
                                            backgroundColor: "#eef8ee",
                                          },
                                        }}
                                        className={` ${styles.dimensionItem} 
                      }`}
                                        style={{
                                          paddingLeft: 0,
                                          borderRadius: 5,
                                          backgroundColor: "unset",
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderLeft: "solid 2px #e0e0e0",
                                            display: "flex",
                                            flexFlow: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginRight: "10px",
                                            width: 85,
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              color: catalogGateMap[
                                                gates[gateIndex].style.id
                                              ]
                                                ? "text.primary"
                                                : "red",
                                            }}
                                          >
                                            {gates[gateIndex].style.name}
                                          </Typography>
                                          <img
                                            style={{
                                              width: "60px",
                                              height: "60px",
                                            }}
                                            src={
                                              gates[gateIndex].style.imageUrl
                                            }
                                            alt={"gate"}
                                            loading="lazy"
                                          />
                                          {/* <Avatar
                            style={{ borderRadius: 3 }}
                            alt={`fence: test`}
                            src={gatesFenceType[feature.id].type.imageUrl}
                          /> */}
                                        </div>
                                        <div></div>

                                        <Box>
                                          <ListItemText
                                            id={labelId}
                                            // primary={`Line item ${feature.id + 1}`}
                                            secondary={
                                              <>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={{
                                                      color:
                                                        catalogGateMap[
                                                          gates[gateIndex].style
                                                            .id
                                                        ] &&
                                                        catalogGateMap[
                                                          gates[gateIndex].style
                                                            .id
                                                        ].materialsGateMap[
                                                          gates[gateIndex]
                                                            .material.id
                                                        ]
                                                          ? "text.primary"
                                                          : "red",
                                                      display: "inline",
                                                    }}
                                                  >
                                                    Material:
                                                  </Typography>
                                                  <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={{
                                                      color:
                                                        catalogGateMap[
                                                          gates[gateIndex].style
                                                            .id
                                                        ] &&
                                                        catalogGateMap[
                                                          gates[gateIndex].style
                                                            .id
                                                        ].materialsGateMap[
                                                          gates[gateIndex]
                                                            .material.id
                                                        ]
                                                          ? "text.primary"
                                                          : "red",
                                                      display: "inline",
                                                    }}
                                                  >
                                                    {
                                                      gates[gateIndex].material
                                                        .name
                                                    }
                                                  </Typography>
                                                </div>
                                              </>
                                            }
                                          />
                                          <div>
                                            {Object.keys(
                                              gates[gateIndex].attributesMap
                                            ).map((attributeId) => {
                                              const styleId =
                                                gates[gateIndex].style.id;
                                              const materialId =
                                                gates[gateIndex].material.id;
                                              const itemId =
                                                gates[gateIndex].attributesMap[
                                                  attributeId
                                                ].item.id;

                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={{
                                                      color:
                                                        catalogGateMap[
                                                          gates[gateIndex].style
                                                            .id
                                                        ] &&
                                                        catalogGateMap[
                                                          gates[gateIndex].style
                                                            .id
                                                        ].materialsGateMap[
                                                          gates[gateIndex]
                                                            .material.id
                                                        ] &&
                                                        catalogGateMap[
                                                          gates[gateIndex].style
                                                            .id
                                                        ].materialsGateMap[
                                                          gates[gateIndex]
                                                            .material.id
                                                        ].attributesGateMap[
                                                          attributeId
                                                        ]
                                                          ? "text.primary"
                                                          : "red",
                                                      display: "inline",
                                                    }}
                                                  >
                                                    <strong>
                                                      {
                                                        globalGateAttributesMap[
                                                          attributeId
                                                        ].name
                                                      }
                                                      :{" "}
                                                    </strong>
                                                  </Typography>

                                                  {catalogGateMap[styleId] &&
                                                  catalogGateMap[styleId]
                                                    .materialsGateMap[
                                                    materialId
                                                  ] &&
                                                  catalogGateMap[styleId]
                                                    .materialsGateMap[
                                                    materialId
                                                  ].attributesGateMap[
                                                    attributeId
                                                  ] &&
                                                  catalogGateMap[styleId]
                                                    .materialsGateMap[
                                                    materialId
                                                  ].attributesGateMap[
                                                    attributeId
                                                  ].itemsGateMap[itemId] ? (
                                                    <Typography
                                                      component="span"
                                                      variant="body2"
                                                      sx={{
                                                        color:
                                                          catalogGateMap[
                                                            styleId
                                                          ] &&
                                                          catalogGateMap[
                                                            styleId
                                                          ].materialsGateMap &&
                                                          catalogGateMap[
                                                            styleId
                                                          ].materialsGateMap[
                                                            materialId
                                                          ] &&
                                                          catalogGateMap[
                                                            styleId
                                                          ].materialsGateMap[
                                                            materialId
                                                          ].attributesGateMap[
                                                            attributeId
                                                          ]
                                                            ? "text.primary"
                                                            : "red",
                                                        display: "inline",
                                                      }}
                                                    >
                                                      {
                                                        gates[gateIndex]
                                                          .attributesMap[
                                                          attributeId
                                                        ].item.name
                                                      }{" "}
                                                      - $
                                                      {catalogGateMap[styleId]
                                                        .materialsGateMap[
                                                        materialId
                                                      ].attributesGateMap[
                                                        attributeId
                                                      ].itemsGateMap[itemId]
                                                        .price || ""}{" "}
                                                    </Typography>
                                                  ) : (
                                                    <Typography
                                                      component="span"
                                                      variant="body2"
                                                      sx={{
                                                        color: "red",
                                                        display: "inline",
                                                      }}
                                                    >
                                                      {/* attribute name */}
                                                      {
                                                        gates[gateIndex]
                                                          .attributesMap[
                                                          attributeId
                                                        ].item.name
                                                      }
                                                    </Typography>
                                                  )}
                                                </div>
                                              );
                                            })}
                                            <div>
                                              Gate price: $
                                              {getGatePrice(
                                                gates[gateIndex],
                                                catalogGateMap
                                              )}
                                            </div>
                                          </div>
                                        </Box>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: "0 6px",
                                          }}
                                        >
                                          <IconButton
                                            disabled
                                            edge="end"
                                            aria-label="edit"
                                          >
                                            <EditIcon
                                              sx={{ visibility: "hidden" }}
                                            />
                                          </IconButton>
                                          <IconButton
                                            onClick={(e) => {
                                              setDeleteModalOpen(true);
                                            }}
                                            edge="end"
                                            aria-label="delete"
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </div>
                                      </ListItemButton>
                                    </ListItem>
                                  </>
                                );
                              }
                            )}
                        </>
                      );
                    }
                  }
                })}
            </List>
          </AccordionDetails>
          <div
            style={{
              position: "sticky",
              bottom: "-12px",
              background:
                "linear-gradient(rgb(255 255 255 / 89%), rgb(255 255 255 / 95%)",
              paddingBottom: "12px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <AccordionSummary>
              {" "}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">Total Quote Price:</Typography>
                <Typography variant="h6">
                  $
                  {getTotalQuotePrice(
                    features,
                    featuresFenceType,
                    catalogMap,
                    gates,
                    catalogGateMap
                  )}
                </Typography>
              </div>
            </AccordionSummary>
            {hasSelection && (
              <>
                <CheckoutPage
                  disabled={
                    !checkAllFeaturesForCatalogItems(
                      features,
                      featuresFenceType,
                      catalogMap
                    ) ||
                    !checkAllFeaturesForGateCatalogItems(gates, catalogGateMap)
                  }
                  draw={draw}
                  totalPrice={totalPrice}
                  deleteAll={clearAll}
                />
              </>
            )}
            {(!checkAllFeaturesForCatalogItems(
              features,
              featuresFenceType,
              catalogMap
            ) ||
              !checkAllFeaturesForGateCatalogItems(gates, catalogGateMap)) && (
              <Typography variant="caption">
                You have items selected that are no longer provided. <br></br>{" "}
                Please change all red items.
              </Typography>
            )}
          </div>
        </Accordion>
      </AccordionGroup>

      <Modal
        sx={{ zIndex: 10000 }}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            Are you sure you want to delete this feature?
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              onClick={() => {
                if (selectedLineOrGate.isFeature) {
                  changeMode("trash", [selectedLineOrGate.id]);
                  dispatch(
                    removeFeatureFenceType({ id: selectedLineOrGate.id })
                  );
                } else {
                  dispatch(removeGate(selectedLineOrGate.id));
                  postFeaturesToFirebase();
                }
                setDeleteModalOpen(false);
              }}
            >
              Delete
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      {modifyFenceType && (
        <ChooseFenceDialog
          changeMode={changeMode}
          open={modifyFenceType}
          setOpen={setModifyFenceType}
          modifyMode={true}
          featureId={selectedLineOrGate.id}
        />
      )}
    </>
  );
};

export default DimensionList;
