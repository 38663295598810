import * as turf from "@turf/turf";

export const formatFeatureToMapbox = (feature) => {
  const modifiedFeature = {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: feature.geometry.coordinates.map((coord) => [
        coord.lng,
        coord.lat,
      ]),
    },
  };
  return modifiedFeature;
};

export const timestampToISO = (timestamp) => {
  try {
    return new Date(timestamp.seconds * 1000).toISOString();
  } catch (error) {
    console.log("error converting timestamp to ISO: ", timestamp);
  }
};

export const isWithinMapBounds = (lng, lat, map) => {
  const mapBounds = map.current.getBounds();

  const [northWestLng, northWestLat] = [
    mapBounds.getWest(),
    mapBounds.getNorth(),
  ];
  const [southEastLng, southEastLat] = [
    mapBounds.getEast(),
    mapBounds.getSouth(),
  ];

  const withinBounds =
    lng >= northWestLng &&
    lng <= southEastLng &&
    lat >= southEastLat &&
    lat <= northWestLat;

  if (withinBounds) {
    return true;
  } else {
    return false; // Or handle as needed, e.g., hide the icon
  }
};

export const roundNumber = (number) => {
  // return Math.round(number * 100) / 100; // round to the tenth place
  return Math.round(number); // round to the nearest whole number
};

export const getFeatureLength = (feature) => {
  const segmentLength = turf.length(feature, { units: "feet" });
  return roundNumber(segmentLength);
};

export const getTotalQuotePrice = (
  features,
  featuresFenceType,
  catalogMap,
  gates,
  catalogGateMap
) => {
  let grandTotalPriceSum = 0;
  features.map((feature) => {
    if (featuresFenceType[feature.id]) {
      let totalPrice = 0;

      Object.keys(featuresFenceType[feature.id].attributesMap).map(
        (attributeId) => {
          const styleId = featuresFenceType[feature.id].style.id;
          const materialId = featuresFenceType[feature.id].material.id;
          const itemId =
            featuresFenceType[feature.id].attributesMap[attributeId].item.id;

          const hasStyle = catalogMap[styleId];
          const hasMaterial = hasStyle
            ? hasStyle.materialsMap[materialId]
            : null;
          const hasAttribute = hasMaterial
            ? hasMaterial.attributesMap[attributeId]
            : null;
          const hasItem = hasAttribute ? hasAttribute.itemsMap[itemId] : null;
          const hasPrice = hasItem ? hasItem.price : null;

          let price = 0;

          if (hasStyle && hasMaterial && hasAttribute && hasItem && hasPrice) {
            price =
              catalogMap[styleId].materialsMap[materialId].attributesMap[
                attributeId
              ].itemsMap[itemId].price;
          }

          const priceToNumber = Number(price);

          const featureLength = getFeatureLength(feature);

          const lengthTimesPrice = featureLength * priceToNumber;

          totalPrice += lengthTimesPrice;
        }
      );

      // setGrandTotalPrice((prev) => {
      //   return prev + totalPrice * Math.floor(getFeatureLength(feature));
      // });
      grandTotalPriceSum += Number(totalPrice);
    }
  });

  // Get gate prices

  gates.forEach((gateData) => {
    const gatePrice = getGatePrice(gateData, catalogGateMap);
    grandTotalPriceSum += gatePrice;
  });

  return Math.floor(grandTotalPriceSum);
};

export const getGatePrice = (gateData, catalogGateMap) => {
  let price = 0;

  Object.keys(gateData.attributesMap).map((attributeId) => {
    const hasStyle = catalogGateMap[gateData.style.id];
    const hasMaterial = hasStyle
      ? hasStyle.materialsGateMap[gateData.material.id]
      : null;
    const hasAttribute = hasMaterial
      ? hasMaterial.attributesGateMap[attributeId]
      : null;
    const hasItem = hasAttribute
      ? hasAttribute.itemsGateMap[gateData.attributesMap[attributeId].item.id]
      : null;
    const hasPrice = hasItem ? hasItem.price : null;

    if (hasStyle && hasMaterial && hasAttribute && hasItem && hasPrice) {
      price += Number(
        catalogGateMap[gateData.style.id].materialsGateMap[gateData.material.id]
          .attributesGateMap[attributeId].itemsGateMap[
          gateData.attributesMap[attributeId].item.id
        ].price
      );
    }
  });

  return price;
};

export const getAllFeatureLengths = (features) => {
  let totalLength = 0;
  features.map((feature) => {
    totalLength += getFeatureLength(feature);
  });
  return totalLength;
};

export const areItemsInCatalog = (catalogMap, featuresFenceType, featureId) => {
  let itemsAreFound = true;

  if (featuresFenceType[featureId]) {
    Object.keys(featuresFenceType[featureId].attributesMap).map(
      (attributeId) => {
        const styleId = featuresFenceType[featureId].style.id;
        const materialId = featuresFenceType[featureId].material.id;
        const itemId =
          featuresFenceType[featureId].attributesMap[attributeId].item.id;

        const hasStyle = catalogMap[styleId];
        const hasMaterial = hasStyle ? hasStyle.materialsMap[materialId] : null;
        const hasAttribute = hasMaterial
          ? hasMaterial.attributesMap[attributeId]
          : null;
        const hasItem = hasAttribute ? hasAttribute.itemsMap[itemId] : null;
        const hasPrice = hasItem ? hasItem.price : null;

        if (hasStyle && hasMaterial && hasAttribute && hasItem && hasPrice) {
          itemsAreFound = true;
        } else {
          itemsAreFound = false;
        }
      }
    );

    return itemsAreFound;
  }
  return false;
};

export const checkAllFeaturesForCatalogItems = (
  features,
  featuresFenceType,
  catalogMap,
  gates,
  catalogGateMap
) => {
  let itemsAreFound = true;
  features.forEach((feature) => {
    if (!itemsAreFound) return;

    itemsAreFound = areItemsInCatalog(
      catalogMap,
      featuresFenceType,
      feature.id
    );
  });

  return itemsAreFound;
};

export const areGateItemsInCatalog = (catalogGateMap, gates, gateId) => {
  let itemsAreFound = true;

  const gateIndex = gates.findIndex((g) => g.id === gateId);

  if (gates[gateIndex]) {
    Object.keys(gates[gateIndex].attributesMap).map((attributeId) => {
      const styleId = gates[gateIndex].style.id;
      const materialId = gates[gateIndex].material.id;
      const itemId = gates[gateIndex].attributesMap[attributeId].item.id;

      const hasStyle = catalogGateMap[styleId];
      const hasMaterial = hasStyle
        ? hasStyle.materialsGateMap[materialId]
        : null;
      const hasAttribute = hasMaterial
        ? hasMaterial.attributesGateMap[attributeId]
        : null;
      const hasItem = hasAttribute ? hasAttribute.itemsGateMap[itemId] : null;
      const hasPrice = hasItem ? hasItem.price : null;

      if (hasStyle && hasMaterial && hasAttribute && hasItem && hasPrice) {
        itemsAreFound = true;
      } else {
        itemsAreFound = false;
      }
    });

    return itemsAreFound;
  }
  return false;
};

export const checkAllFeaturesForGateCatalogItems = (gates, catalogGateMap) => {
  let itemsAreFound = true;
  gates.forEach((gate) => {
    if (!itemsAreFound) return;

    itemsAreFound = areGateItemsInCatalog(catalogGateMap, gates, gate.id);
  });

  return itemsAreFound;
};
