import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const selectedFence = createSlice({
  name: "selectedFence",
  initialState,
  reducers: {
    selectFence: (state, action) => {
      // state.id = action.payload.id;
      // state.name = action.payload.name;
      // state.imageUrl = action.payload.imageUrl;
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
      // state = newState;

      // state = { ...state, ...action.payload };
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { selectFence, decrement, incrementByAmount } =
  selectedFence.actions;

export default selectedFence.reducer;
